@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.2/font/bootstrap-icons.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/antd/4.21.7/antd.min.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* Header CSS Start Here */
@media (min-width: 1200px) {
    .shortsMakerReactjs header#masthead.site-header {
        max-width: 1184px !important;
    }
}


.completeFormWrapper.CheckingStatus>* {
    display: none;
}


.shortsSectionWrapper .formShortcode .formInputFields .shortsMakerButtonIsClicked.custombtn {
    position: absolute;
    top: -7px;
    height: 100%;
    right: 0;
    border-radius: 8px;
    opacity: 1;
    background-color: #3782f7;
    width: 168px;
    color: #fff;
    text-align: center;
    font-family: Helvetica !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.36px;
    border-style: none;
    transition: all 0.5s ease;
    cursor: pointer;
    min-height: 45px;
}


/* tiktok user profile */
.userInfoCircle {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.userInfoCircle img {
    border-radius: 10px;
    max-width: 50px;
}

.userInfoCircle h3 {
    margin: 0;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
}

.videoPreview video {
    max-height: 350px;
    width: 100%;
}

.videoPreview {
    text-align: center;
    margin: 15px 0px 0px 0px;
    background-color: #000;
}

.discloseVideoContentWrap .form-check.form-switch,
.discloseVideoContentWrap .form-check {
    padding-left: 0 !important;
}

.discloseVideoContentWrap label {
    font-weight: 600 !important;
}

.discloseVideoContentWrap .disclauseCheckboxes p {
    margin: 10px 0px;
}

.disclauseCheckboxes {
    margin-top: 15px;
}

.declarationWrapper p {
    font-weight: 600;
}




/* Tik Tok Tutorial Css Start From here */
img {
    max-width: 100%;
}

.instagram_model .modal-dialog {
    max-width: 420px;
}

.instagram_model.ss_dark .modal-content {
    background-color: rgb(20, 23, 31);
}

.instagram_model {
    overflow: visible;
}

.instagram_model .modal-dialog .modal-content {
    padding: 0;
    overflow-y: auto;
    height: calc(100vh - 100px);
    scrollbar-width: none;
}

.instagram_model .modal-content .modal-header {
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #fff;
    padding: 16px 24px;
}

.instagram_model.ss_dark .modal-content .modal-header {
    background-color: rgb(20, 23, 31);
    border-style: none;
}

.instagram_model .instagram_Tutrial_header h3 {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    line-height: 1.2;
}

.instagram_model.ss_dark .modal-content .modal-header h3 {
    color: #fff;
}

.instagram_model .modal-header .btn-close {
    background-size: 12px;
    padding: 2px;
    box-shadow: none;
}

.instagram_model.ss_dark .modal-content .modal-header .btn-close {
    filter: invert(1);
}

.instagram_model .modal-content .modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 999;
    background-color: #fff;
    padding: 12px 24px 12px 8px;
}

.instagram_model.ss_dark .modal-content .modal-footer {
    background-color: rgb(20, 23, 31);
    border-style: none;
}

.instagram_model .modal-footer .instagram_button {
    padding: 10px 20px;
    border-style: none;
    border-radius: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    min-width: 100px;
    font-size: 14px;
    background-color: rgb(103, 35, 255);
    color: #fff;
    transition: all .5s ease-in-out;
    margin: 0;
}

.instagram_model .modal-footer .instagram_button:hover {
    background-color: rgb(77, 18, 197);
}

.instagram_model .modal-body {
    padding: 10px 24px 20px 24px;
}

.instaram_tutorial_wrap p {
    color: rgb(155, 158, 163);
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    line-height: 1.3;
    display: inline-block;
    margin: 10px 0 0;
}

.instaram_tutorial_wrap .instagram_body_heading img {
    max-width: 16px;
    display: inline-block;
}

.instaram_tutorial_wrap .instgram_content_list {
    list-style: none;
    padding: 0;
    counter-reset: counter;
}

.instaram_tutorial_wrap .instgram_content_list li {
    counter-increment: counter;
}

.instaram_tutorial_wrap .instgram_content_list li h6::before {
    content: counter(counter) ".";
}

.instaram_tutorial_wrap .instgram_content_list h6 {
    display: flex;
    align-items: baseline;
    grid-gap: 6px;
    color: #0d6efd;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Poppins';
    margin: 15px 0 0;
}

.instagram_model.ss_dark .instaram_tutorial_wrap .instgram_content_list h6 {
    color: rgb(74, 222, 128);
}

.instaram_tutorial_wrap .img-container img {
    margin: 15px 0 0;
    border-radius: 12px;
    max-width: 260px;
}

.instagram_model:not(.ss_dark) .img-container img {
    box-shadow: 0 0 10px #e8e8e8;
}

.instaram_tutorial_wrap .img_bottom {
    margin: 20px 0 12px;
}

/* Tik Tok Tutorial Css End From here */

.nav-link.active {

    font-weight: 500;
}

a.nav-link {
    font-size: 16px;
}

h3 {
    font-size: 27px;
}

h1 {
    font-size: 45px;
}

h2 {
    font-size: 35px;
}

/* login button styles */

a.disconnectbtn {
    position: absolute;
    right: 40px;
    z-index: 9999;
}

button.ytConnect img {
    width: 100px;
    margin: 0 auto;
}

button.InstaConnect img {
    width: 30px;
    margin: 0 auto;
}

button.tiktokConnect img {
    width: 30px;
    margin: 0 auto;
}

button.ytConnect {
    font-weight: 500;
}

.sc_small_center {
    width: 70%;
    margin: 0 auto;
}




/* end of login button styles */

/* Compare list styles start here */
.comparisonMainHeadWrapper .mainFeatureHeading {
    display: flex;
}

.subFeaturesWrapper .subFeaturesRow {
    display: flex;
}

.mainInner {
    width: 25%;
}

.subFeaturesWrapper {
    width: 75%;
}

.subFeatureTitle {
    width: 70%;
}

.subFeaturessembleTick {
    width: 10%;
}

.subFeatureOtherTick {
    width: 10%;
}

.compareListingWrapper p {
    padding: 0 !important;
    margin: 15px !important;
}

/* compare list styles end here */

.page-template-template-shorts-react .vlog_tool_wrapper {
    padding-bottom: 0 !important;
}

.shortsSectionWrapper p {
    color: #1b1a23;
    font-size: 18px;
    line-height: 1.6;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 20px 0 40px;
    letter-spacing: 0px;
}

.underHeroheading p {
    text-align: center;
    font-size: 16px;
}

span.ssregular_pricess_strike {
    text-decoration: line-through;
    font-size: 28px;
    font-weight: 700;
}

span.ss_sale_price {
    color: #ff5c00;
}

.shortsMakerButtonWrap .spinner-border {
    margin-right: 10px;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    pointer-events: none !important;
    opacity: 0.25 !important;
}

.main-navigation .addtionalButtonsData {
    display: flex;
    align-items: center;
    grid-gap: 53px;
    margin-right: 24px;
}

.main-navigation .addtionalButtonsData .ss_plan_mintues {
    display: flex;
    align-items: center;
    grid-gap: 0 10px;
}

.main-navigation .addtionalButtonsData .ss_plan_mintues .progress {
    width: 58px;
    height: 5px;
    margin: 0;
}

.shortsMakerReactjs .main-navigation {
    justify-content: flex-end;
    margin: 20px 0px 0px 0px;
}

.top-bar #top-menu {
    padding-right: 20px;
}

.progress.bg-success {
    background-color: #1985534d !important;
}

.progress.bg-danger {
    background-color: #ffd3d3 !important;
}

.main-navigation .addtionalButtonsData .ss_plan_mintues .planTitle {
    color: #7d7d7d;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
}

.main-navigation .addtionalButtonsData .ss_plan_mintues .planTime {
    font-size: 11px;
    font-weight: 400;
    line-height: 1;
}

.loading-sec.loadingSpinnerShorts {
    text-align: center;
    margin: 0;
    margin-top: 40px;
}

.main-navigation .addtionalButtonsData .ss_plan_mintues .planTime.danger {
    color: #ff2424;
}

.main-navigation .addtionalButtonsData .ss_plan_mintues span.planTime.success {
    color: #198754;
}

.FeedbackContentRepeat h3,
.vidoesThumbWrapper h3 {
    font-weight: 700 !important;
}

.aishorts-content h3 a {
    color: #000;
}

.main-navigation .addtionalButtonsData button.ss_gradient {
    border-radius: 6px;
    background: linear-gradient(93deg,
            #ff603c 0%,
            #8558fc 73.19%,
            #0116d1 113.18%);
    color: #fff;
    text-align: center;
    font-family: "SF Compact Display" !important;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.32px;
    min-height: 40px;
    min-width: 174px;
    line-height: 1;
    border-style: none;
    padding: 0;
    transition: all 0.5s ease;
}

ul.worksBestTalkingVideos {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-auto-flow: column;
}

/* Header CSS Close Here */
.accordion-collapse.collapse.show {
    visibility: visible;
}

.page-template-template-shorts-react .ModalWrapper .pricing-tab-card.free {
    display: block !important;
}

/* Non Logged In View CSS Start Here */

span.lazy-load-image-background.lazy-load-image-loaded {
    max-width: 100%;
}

.shortsSectionWrapper .formShortcode .formInputFields input.youtubeChannel {
    border-radius: 8px;
    background-color: #f1f2f4;
    border: 1px solid #f1f2f4;
    box-shadow: none;
    min-height: 48px;
    padding: 0 180px 0 15px;
    font-family: "Noto Sans", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.shortsSectionWrapper .formShortcode .formInputFields input.youtubeChannel::placeholder {
    color: #c3c3c3;
}

.shortsSectionWrapper .formShortcode .formInputFields .shortsMakerButtonIsClicked {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    border-radius: 8px;
    opacity: 1;
    background-color: #3782f7;
    width: 168px;
    color: #fff;
    text-align: center;
    font-family: Helvetica !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    border-style: none;
    transition: all 0.5s ease;
    cursor: pointer;
}

.shortsSectionWrapper .formShortcode .formInputFields .shortsMakerButtonIsClicked:hover {
    background-color: #0f6dec;
}

.shortsSectionWrapper .formShortcode .formInputFields label#youtubeChannel-error {
    bottom: -28px !important;
    font-family: "Noto Sans", sans-serif !important;
}

.shortsSectionWrapper .faqWrapper h4 {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 4px;
}

.shortsSectionWrapper .faqWrapper .accordion-item {
    border-style: none;
}

.shortsSectionWrapper .faqWrapper .accordion-item:not(:last-child) {
    border-bottom: 1px solid #e8e8e8;
}

.shortsSectionWrapper .faqWrapper .accordion-item .accordion-header .accordion-button {
    border-style: none;
    box-shadow: none;
    background-color: transparent;
    padding: 20px 0;
    color: #000;
    font-size: 18px;
    font-family: "Canva Sans" !important;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
}

.shortsSectionWrapper .faqWrapper .accordion-item .accordion-header .accordion-button:hover {
    color: #3782f7;
}

.accordion-button::after {
    transform: rotate(180deg);
}

.shortsSectionWrapper .faqWrapper .accordion-item .accordion-body {
    padding: 0 0 15px 0;
    color: #6a6a6a;
    font-family: "Canva Sans" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.freeSapmleClipsWrapper .formShortcode .formInputFields input.youtubeChannel {
    background-color: #fff;
}

@media (max-width: 991px) {
    .shortsSectionWrapper .formShortcode .formInputFields .shortsMakerButtonIsClicked {
        width: 135px;
        font-size: 14px;
    }

    .shortsSectionWrapper .formShortcode .formInputFields input.youtubeChannel {
        padding: 0 140px 0 10px;
    }
}

@media (max-width: 420px) {
    .shortsSectionWrapper .formShortcode .formInputFields .shortsMakerButtonIsClicked {
        width: 100%;
        position: static;
        margin-top: 10px;
        padding: 12px;
    }

    .shortsSectionWrapper .formShortcode .formInputFields input.youtubeChannel {
        padding: 0 10px;
    }
}

/* Non Logged In View CSS Clsoe Here  */
/* Logged In View CSS Start Here  */
.loginGenerateShortsWrapper .formShortcode .formInputFields input.youtubeChannel {
    box-shadow: none;
}

.loginGenerateShortsWrapper .formShortcode .formInputFields .submitField .shortsMakerButtonIsClicked {
    border-radius: 10px;
    background-color: #3782f7;
    border-style: none;
    width: 271px;
    height: 48px;
    color: #fff;
    text-align: center;
    display: block;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin: 40px auto;
    transition: all 0.7s ease;
}

.loginGenerateShortsWrapper .formShortcode .formInputFields .submitField .shortsMakerButtonIsClicked:hover {
    background-color: #0f6dec;
}



.loginGenerateShortsWrapper .loginGeneratorShortsContentBox .shortsMakersTalkingVideos .worksBestTalkingVideos li {
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center left;
    padding-left: 28px;
    margin-bottom: 5px;
}

.loginGenerateShortsWrapper .shortsRangeError {
    color: #f00000;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    font-family: "Poppins", sans-serif !important;
    line-height: 1.5;
    max-width: 435px;
    margin: 0 auto;
    padding-top: 20px;
}

.page-template-template-shorts-react .ss_modal.modal .pricing-heading {
    display: flex;
    align-items: center;
}

.page-template-template-shorts-react .fade.ss_modal.modal .modal-dialog .modal-content .pricing-heading h2 {
    font-size: 22px;
    font-weight: 500;
}

.page-template-template-shorts-react .fade.ss_modal.modal .modal-dialog .modal-content .pricing-btn {
    padding: 0;
}

.tools-heading .shortMaker-heading {
    color: #000;
    text-align: center;
    font-family: Helvetica !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 60.5px;
    margin-bottom: 8px;
    letter-spacing: 0;
}

.tools-heading .shortmakerSub-heading {
    color: #000;
    text-align: center;
    font-family: Helvetica !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 0;
}

.loginGenerateShortsWrapper .youtube-down-con .percentage-text {
    color: #979797;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 40px;
}

.loginGenerateShortsWrapper .youtube-down-con p.shortsLoadingLabel {
    color: #979797;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
}

.loginGenerateShortsWrapper .youtube-down-con .secondary-btn button {
    border-radius: 10px;
    background: #f1f2f4;
    border-style: none;
    width: 271px;
    height: 48px;
    display: block;
    margin: 0 auto;
    transition: all 0.3s ease;
    color: #626262;
    text-align: center;
    font-family: Helvetica !important;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
}

.loginGenerateShortsWrapper .shortsImageWrapper {
    margin-top: 50px;
}

.loginGenerateShortsWrapper .shortsImageWrapper img {
    width: 435px;
    border-radius: 8px;
    display: block;
    margin: 0 auto;
}

.loginGenerateShortsWrapper .youtube-down-con .secondary-btn button:hover {
    background: #ccc;
    color: #fff;
}

.loginGenerateShortsWrapper .loginGeneratorThumbnail.shortsChildContainer figure img {
    border-radius: 8px;
}

@media (max-width: 575px) {
    .loginGenerateShortsWrapper .formShortcode .formInputFields .submitField .shortsMakerButtonIsClicked {
        width: 100%;
        font-size: 16px;
        height: 40px;
    }

    .loginGenerateShortsWrapper .youtube-down-con .secondary-btn button {
        width: 100%;
    }

    .tools-heading .shortMaker-heading {
        font-size: 30px;
        line-height: 36px;
    }

    .tools-heading .shortmakerSub-heading,
    .loginGenerateShortsWrapper .youtube-down-con p.shortsLoadingLabel {
        font-size: 20px;
        line-height: 26px;
    }

    .loginGenerateShortsWrapper .loginGeneratorShortsContainer .container {
        padding: 0;
    }
}

/* Logged In View CSS Close Here  */

/* Shorts Popup CSS Start Here */
.page-template-template-shorts-react .fade.ss_modal.modal::-webkit-scrollbar {
    display: none;
}

.page-template-template-shorts-react .fade.ss_modal.modal {
    padding-right: 0 !important;
}

.page-template-template-shorts-react .fade.ss_modal.modal .modal-dialog {
    max-width: 875px;
    border-radius: 16px;
}

.page-template-template-shorts-react .fade.ss_modal.modal .modal-dialog .modal-content {
    padding: 30px 40px 30px 25px;
}

.page-template-template-shorts-react .ModalWrapper .pricing-tab-content.ssembleYtDownloaderPrice {
    margin: 15px 0 0 !important;
    max-width: 100%;
}

.page-template-template-shorts-react .ModalWrapper {
    display: grid;
    grid-template-columns: 35% 65%;
}

.page-template-template-shorts-react .ModalWrapper .leftContent {
    margin-top: 100px;
}

.page-template-template-shorts-react .ModalWrapper .ssPricingTableWrapper {
    padding-top: 0;
}

.page-template-template-shorts-react .ModalWrapper .pricing-tab-content .nav.nav-tabs {
    margin: 0 auto 30px;
    max-width: 175px;
}

.page-template-template-shorts-react .ModalWrapper .pricing-tab-content .nav.nav-tabs li.nav-item button {
    padding: 9px 17px;
}

.page-template-template-shorts-react .ModalWrapper .pricing-tab-content .nav.nav-tabs li.nav-item .save-up span {
    background-color: transparent;
    position: static !important;
    padding: 0 !important;
    width: fit-content;
    max-width: 100%;
    color: #fe8000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 0;
    display: inline;
}

.page-template-template-shorts-react .ModalWrapper .pricing-tab-content .nav.nav-tabs li.nav-item .save-up span::before {
    display: none;
}

.page-template-template-shorts-react .ModalWrapper .pricing-tab-content .nav.nav-tabs li.nav-item .save-up {
    right: 0;
    left: auto;
    position: absolute;
    transform: translatex(0);
    width: fit-content;
    top: -24px;
    text-align: right;
}

.page-template-template-shorts-react .ModalWrapper .leftContent .shortsClipsWrapper img {
    max-width: 62px;
}

.page-template-template-shorts-react .ModalWrapper .leftContent .shortsClipsWrapper .shortsClipHeading {
    color: #000;
    font-family: "SF Compact Display" !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    margin: 20px 0 50px;
}

.page-template-template-shorts-react .ModalWrapper .leftContent .shortsClipsWrapper .shortsClipContent {
    color: #7d7d7d;
    font-family: "SF Compact Display" !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 457;
    line-height: 138%;
    width: 80%;
}

.page-template-template-shorts-react .fade.ss_modal.modal .modal-dialog .ssemblePricingWrapperInner {
    min-height: 85px;
}

/******************/
.customRadioBtnGroup {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
}

.customRadioBtns.form-check {
    position: relative;
    padding: 4px 4px 0px 0px;
}

.customRadioBtns input[type="radio"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.customRadioBtns label {
    border-radius: 9px;
    padding: 9px 8px;
    background: #f0f1f2;
    color: #636363;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    border-style: none;
}

.customRadioBtns input[type="radio"]:checked+label,
customRadioBtns input[type="radio"]:hover+label {
    background: #f0f6ff;
    color: #3782f7;
}

/* Popup Responsive */
@media (max-width: 991px) {
    .page-template-template-shorts-react .ModalWrapper {
        grid-template-columns: 1fr;
        grid-gap: 40px 0;
    }

    .page-template-template-shorts-react .ModalWrapper .leftContent {
        margin-top: 0;
        text-align: center;
    }

    .page-template-template-shorts-react .ModalWrapper .leftContent .shortsClipsWrapper .shortsClipHeading {
        margin: 15px 0 30px;
    }

    .page-template-template-shorts-react .ModalWrapper .leftContent .shortsClipsWrapper .shortsClipContent {
        width: 100%;
        margin: 0;
    }
}

@media (max-width: 575px) {
    .page-template-template-shorts-react .fade.ss_modal.modal .modal-dialog .modal-content {
        padding: 30px 20px;
    }

    .page-template-template-shorts-react .fade.ss_modal.modal .ssPricingTableWrapper .pricing-tab-card .pricing-list ul {
        margin: 0;
    }

    .page-template-template-shorts-react .fade.ss_modal.modal .modal-dialog .ssemblePricingWrapperInner {
        min-height: fit-content;
    }
}

@media (max-width: 360px) {
    .page-template-template-shorts-react .ModalWrapper .pricing-tab-content.ssembleYtDownloaderPrice .pricing-tab-card {
        margin: 16px 0 !important;
    }
}

/* Shorts Popup CSS Close Here */
/* Pro User Popup CSS start Here */
.fade.ss_modal.modal.proUserPopup .modal-dialog .modal-content {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.6);
    min-height: 80px;
    padding: 18px 15px;
    max-width: 600px;
}

.fade.ss_modal.modal.proUserPopup .modal-dialog .modal-content .modal-body p {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145.053%;
    font-family: "Noto Sans", sans-serif !important;
    text-align: center;
}

.fade.ss_modal.modal.proUserPopup .modal-dialog .modal-content .modal-header {
    top: 5px;
    right: 5px;
}

.fade.ss_modal.modal.proUserPopup .modal-dialog .modal-content .modal-header button.btn-close {
    filter: brightness(0) invert(1);
    opacity: 1;
}

.fade.ss_modal.modal.proUserPopup .modal-dialog .modal-content .modal-body p a {
    color: #fff;
    font-weight: 700;
    text-decoration: underline;
}

.fade.ss_modal.modal.proUserPopup .modal-dialog .modal-content .modal-body p a:hover {
    color: #0d6efd;
}

/* Pro User Popup CSS Close Here */

/* Shorts Page CSS Are Start Here */
/* Shorts Page Header CSS Are Start Here */
.page-template-template-shorts header#masthead {
    justify-content: space-between;
    max-width: 1140px !important;
}

.page-template-template-shorts header#masthead #top-menu {
    display: flex;
    align-items: center;
}

.page-template-template-shorts header#masthead>* {
    flex: 1;
    float: none;
}

.page-template-template-shorts header#masthead .main-navigation {
    justify-content: flex-end;
}

.page-template-template-shorts header#masthead div#site-logo img {
    max-width: 250px;
}

/* Shorts Page Header CSS Are Close Here */
/* Shorts Page Hero CSS Are Start Here */

.submitField.submitting:before {
    content: "";
    width: 25px;
    height: 25px;
    border: 4px solid #fff;
    border-bottom-color: #ff3d00;
    border-radius: 50%;
    display: inline-block;
    animation: rotation 1s linear infinite;
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    z-index: 55;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.submitField.submitting {
    position: relative;
}

.submitField.submitting input[type="submit"] {
    font-size: 0px !important;
    opacity: 0.3;
}

.shortsSectionWrapper {
    padding: 120px 0 0;
}

.shortsSectionWrapper .shorts-container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 16px;
}

.shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    flex-wrap: wrap;
}

.shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .left_section {
    flex: 1;
}

.shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .right_section {
    width: 41.8%;
}

.shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .imageWrapper {
    width: 100%;
}

.shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .left_section h1 {
    color: #1b1a23;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 62px;
    letter-spacing: -2px;
    margin: 0;
}

.shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .left_section p {
    color: #1b1a23;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 20px 0 40px;
    letter-spacing: 0px;
}

.shortsSectionWrapper input:focus {
    outline: none;
}

.shortsSectionWrapper .formShortcode .formFieldsGroup label {
    color: #1b1a23;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin: 0 0 5px;
    display: block;
    text-transform: capitalize;
}

.shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .shortsFormWrappr label.error {
    color: #ff0022;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    position: absolute;
    margin: 5px 0 0;
    bottom: -30px;
    left: 0;
}

.shortsSectionWrapper .formShortcode {
    min-height: 80px;
    position: relative;
}

.shortsSectionWrapper .formShortcode .formInputFields {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.shortsSectionWrapper .formShortcode .formInputFields .inputField {
    flex: 1;
}

.shortsSectionWrapper .formShortcode .formInputFields .submitField {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 165px;
    width: 100%;
    text-align: right;
}

.shortsSectionWrapper .formShortcode .formInputFields .inputField input {
    border-radius: 8px;
    background: #f1f2f4;
    width: 100%;
    min-height: 48px;
    display: block;
    border: 3px solid #f1f2f4 !important;
    padding: 0 170px 0 15px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    font-family: "Noto Sans", sans-serif !important;
}

.shortsSectionWrapper .formShortcode .formInputFields .inputField input::placeholder {
    color: #c3c3c3;
}

.shortsSectionWrapper .formShortcode .formInputFields .submitField input[type="submit"] {
    border-radius: 8px;
    background: #3782f7;
    width: 100%;
    min-height: 48px;
    display: block;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0;
    padding: 0;
    border-style: none;
    box-shadow: none;
    transition: all 0.7s ease;
}

.shortsSectionWrapper .formShortcode .formInputFields .submitField input[type="submit"]:hover {
    background: #0f6dec;
}

/* Shorts Page Hero CSS Are Close Here */
/* Shorts Page Under Hero CSS Are Start Here */

.shortsSectionWrapper .underHeroWrapper .underHeroInner {
    max-width: 850px;
    margin: 0 auto;
}

.shortsSectionWrapper .underHeroWrapper .underHeroheading h2 {
    color: #1b1a23;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4;
}

.shortsSectionWrapper .underHeroWrapper .underHeroImgGroup {
    display: flex;
    justify-content: center;
    grid-gap: 32px;
    flex-wrap: wrap;
    text-align: center;
    padding: 0;
    margin: 0;
}

.shortsSectionWrapper .underHeroWrapper .underHeroImgGroup .underHeroImage {
    width: calc(33.3% - 32px);
}

.shortsSectionWrapper .underHeroWrapper .underHeroImgGroup .underHeroImage img {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

/* Shorts Page Under Hero CSS Are Close Here */
/* Shorts Page Videos Thumb Img CSS Are Start Here */

.multipleVideosThumbnailWrapper {
    text-align: center;
}

.shortsSectionWrapper .multipleVideosThumbnailWrapper {
    background: linear-gradient(180deg,
            #f2f5f9 35.08%,
            rgba(242, 245, 249, 0) 88.95%);
    padding: 100px 0 20px;
    margin-top: 100px;
}

/* .shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 60px;
    flex-wrap: wrap;
} */

.shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper>* {
    flex: 1;
}

.shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper .videoThumbContent h2 {
    color: #1b1a23;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 62px;
    letter-spacing: 0.45px;
    margin: 0;
    text-transform: capitalize;
}

.shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper .videoThumbContent p {
    color: #1b1a23;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin: 0;
}

/* Shorts Page Videos Thumb Img CSS Are Close Here */
/* Shorts Page Streamline shorts making CSS Are Start Here */
.shortsSectionWrapper .straemlineShortsMakingWrapper {
    padding-top: 0px;
    text-align: center;
}

.straemlineShortsMakingWrapper .streamLineShortsheading {
    padding-bottom: 40px;
}

.shortsSectionWrapper h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 62px;
    letter-spacing: 0;
    margin: 0;
}

.shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 0 40px;
}

.shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner .StreamlineShortsColumn {
    flex: 1;
    border-radius: 9px;
    background: #f2f5f9;
    padding: 70px 48px;
    border-radius: 20px;
}

.shortsSectionWrapper .straemlineShortsMakingWrapper .StreamlineShortsColumn .title {
    color: #1b1a23;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0;
    margin: 0 0 30px;
}

.shortsSectionWrapper .straemlineShortsMakingWrapper .StreamlineShortsColumn .description {
    color: #1b1a23;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
}

/* Shorts Page Streamline shorts making CSS Are Close Here */
/* Shorts Page AI-generated shorts CSS Are Start Here */
.shortsSectionWrapper .aiGenerateShortsWrapper {
    margin-top: 100px;
    padding: 100px 0;
    background-color: #f2f5f9;
    text-align: center;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .generateshortsheading {
    max-width: 800px;
    margin: 0 auto 40px;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGenerateShortsRow {
    max-width: 825px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    grid-gap: 0 30px;
    letter-spacing: 0;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn {
    border-radius: 20px;
    background: #fff;
    flex: 1;
    padding: 32px 50px;
    position: relative;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .planTitle {
    color: #24282f;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    line-height: 134.48%;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .price {
    color: #24282f;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 134.48%;
    margin: 12px 0 6px;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn+.aiGeneratesColumn .regularPrice {
    text-decoration: line-through;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .price .salesPrice {
    color: #ff5c00;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .offMessage {
    position: absolute;
    top: -24px;
    right: 0;
    max-width: 132px;
    height: 48px;
    width: 100%;
    border-radius: 20px;
    background-color: #ff5c00;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .offMessage p {
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .shortsPerMonth {
    color: #7d7d7d;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 116%;
    margin-bottom: 40px;
    font-family: "SF Compact Display" !important;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .contactUsLink {
    border-radius: 6px;
    background: #3782f7;
    width: 100%;
    display: block;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 134.48%;
    padding: 14px 0;
    transition: all 0.5s ease;
    font-family: "Noto Sans", sans-serif !important;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .contactUsLink:hover {
    background: #0f6dec;
}

/* Shorts Page AI-generated shorts CSS Are Close Here */
.shortsSectionWrapper *:not(.aiGenerateShortsRow *) {
    font-family: Helvetica !important;
}

.shortsSectionWrapper .aiGenerateShortsRow * {
    font-family: "Poppins", sans-serif !important;
}

/* Shorts Page FAQs CSS Are Start Here */
.shortsSectionWrapper .faqWrapper {
    margin-top: 100px;
}

.shortsSectionWrapper .faqWrapper .feature-faq {
    margin: 0 auto;
    max-width: 1140px;
    padding: 0 16px;
}

.shortsSectionWrapper .faqWrapper .feature-faq {
    margin: 0 auto;
}

.shortsSectionWrapper .faqWrapper .feature-faq h4 {
    margin: 0 0 32px;
}

.shortsSectionWrapper .faqWrapper .feature-faq .accordion-header h2 {
    line-height: 26px;
}

.shortsSectionWrapper .faqWrapper .feature-faq .accordion-item .accordion-collapse .accordion-body p {
    font-size: 16px;
    max-width: 960px;
}

/* Shorts Page FAQs CSS Are Close Here */
/* Shorts Page Free Sample Clips CSS Are Start Here */
.shortsSectionWrapper .freeSapmleClipsWrapper {
    margin-top: 100px;
}

.shortsSectionWrapper .freeSapmleClipsWrapper .freeSampleClipsInner {
    border-radius: 20px;
    background: #f2f5f9;
    padding: 100px 0;
}

.shortsSectionWrapper .freeSapmleClipsWrapper .freeSampleHeading {
    text-align: center;
    padding-bottom: 40px;
}

.shortsSectionWrapper .freeSapmleClipsWrapper .formShortcode {
    max-width: 600px;
    margin: 0 auto;
}

.shortsSectionWrapper .freeSapmleClipsWrapper .formShortcode .formInputFields .inputField input {
    background: #fff;
    border: 3px solid #ffffff !important;
}

.shortsSectionWrapper .formShortcode .formInputFields label#youtubeChannel-error {
    color: #ff0033;
    position: absolute;
    bottom: -35px;
    font-weight: 500;
}

/* Shorts Page Free Sample Clips CSS Are Close Here */
/* Shorts Page Success Section CSS Are Close Here */
.successSectionWrapper {
    padding: 120px 0;
}

.successSectionWrapper .shorts-container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 16px;
}

.successSectionWrapper .success-headings_wrapper {
    display: flex;
    align-items: center;
    grid-gap: 0 30px;
}

.successSectionWrapper .success-headings_wrapper .successLoader {
    width: 100%;
    height: 40px;
    max-width: 40px;
    border: 5px solid #787878;
    border-top: 5px solid rgb(216, 216, 216);
    border-radius: 50%;
    animation: reload 1s infinite linear;
}

@keyframes reload {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

.successSectionWrapper .successSectionHeading h2 {
    color: #000;
    font-size: 40px;
    font-weight: 700;
    line-height: 60.5px;
    margin: 0;
}

.successSectionWrapper .successSectionSubHead h4 {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 8px 0 0;
}

.successSectionWrapper .successSectionContent {
    border-radius: 8px;
    background: #f1f2f4;
    padding: 33px 68px;
    margin-top: 30px;
}

.successSectionWrapper .successSectionContent p {
    color: #1b1a23;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
}

.successSectionWrapper .successSectionContent p:not(:last-child) {
    margin: 0 0 18px;
}

/* Shorts Page Success Section CSS Are Close Here */

.ssemble_image_skelton {
    background-color: #e2e5e7;
    background-image: linear-gradient(90deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    border-radius: 20px;
    animation: shine 1s ease infinite;
}

/* Shorts Page Skeleton CSS Are Start Here */
.shortsSekletonWrapper {
    padding: 120px 15px;
    max-width: 600px;
    margin: 0 auto;
}

.shortsSekletonWrapper .shortsSkeleton {
    background-color: #e2e5e7;
    background-image: linear-gradient(90deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    animation: shine 1s ease infinite;
}

@keyframes shine {
    to {
        background-position: right -40px top 0;
    }
}

.shortsSekletonWrapper .shortsSkeletonLabel {
    width: 125px;
    height: 18px;
    border-radius: 4px;
}

.shortsSekletonWrapper .shortsSkeletonInput {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    margin: 8px 0 30px;
}

.shortsSekletonWrapper .shortsSkeletonButton {
    width: 200px;
    height: 48px;
    margin: 0 auto;
    border-radius: 10px;
}

.shortsSekletonWrapper .shortsSkeletonContent {
    width: 100%;
    height: 250px;
    border-radius: 8px;
    margin-top: 50px;
}

/* Shorts Page Skeleton CSS Are Close Here */
/* Shorts Maker Login Generate Shorts Section CSS Are Start Here */
.loginGenerateShortsWrapper {
    padding: 0px;
}

.loginGenerateShortsWrapper .loginGeneratorShortsContainer {
    max-width: 600px;
    margin: 0 auto;
}

.loginGenerateShortsWrapper .formShortcode label {
    color: #1b1a23;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    font-family: Helvetica !important;
    line-height: 26px;
}

.loginGenerateShortsWrapper .formShortcode label.error {
    color: red;
    font-weight: 600;
    margin: 0;
}

.loginGenerateShortsWrapper .formShortcode .formInputFields input.youtubeChannel::placeholder {
    color: #c3c3c3;
}

.loginGenerateShortsWrapper .formShortcode .formInputFields input.youtubeChannel {
    border-radius: 8px;
    background: #f1f2f4;
    outline: none !important;
    border: 1px solid #f1f2f4;
    padding: 0 14px;
    display: block;
    width: 100%;
    height: 48px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    font-family: "Noto Sans", sans-serif !important;
}

.loginGenerateShortsWrapper .formShortcode .submitField input[type="submit"] {
    border-radius: 10px;
    background: #3782f7;
    width: 250px;
    margin: 40px auto;
    display: block;
    height: 48px;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    font-family: Helvetica !important;
    transition: all 0.5s ease;
    border-style: none;
}

.loginGenerateShortsWrapper .formShortcode .submitField input[type="submit"]:hover {
    background: #0f6dec;
}

.loginGenerateShortsWrapper .loginGeneratorShortsContentBox .loginGeneratorContentBoxInner {
    max-width: 425px;
    margin: 0 auto;
}

.loginGenerateShortsWrapper .loginGeneratorShortsContentBox {
    border-radius: 8px;
    background: #f8f8f8;
    padding: 20px 30px 30px;
}

.loginGenerateShortsWrapper .loginGeneratorShortsContentBox h6.generatorShorts {
    color: #1b1a23;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: Helvetica !important;
    margin: 0;
}

.loginGenerateShortsWrapper .loginGeneratorShortsContentBox .shortsMakersTalkingVideos {
    display: flex;
    justify-content: space-between;
    grid-gap: 0 40px;
    margin-top: 40px;
    flex-wrap: wrap;
}

.loginGenerateShortsWrapper .loginGeneratorShortsContentBox .shortsMakersTalkingVideos .worksBestTalkingVideos {
    margin: 0;
    padding: 0;
    flex: 1;
}

.loginGenerateShortsWrapper .loginGeneratorShortsContentBox .shortsMakersTalkingVideos .worksBestTalkingVideos li {
    color: #1b1a23;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: Helvetica !important;
}

.loginGenerateShortsWrapper .loginGeneratorThumbnail.shortsChildContainer {
    text-align: center;
    margin: 40px auto;
}

.loginGenerateShortsWrapper .shortsChildContainer {
    max-width: 435px;
    margin: 0 auto;
}

.loginGenerateShortsWrapper .loginGeneratorThumbnail figure {
    margin: 0;
}

.loginGenerateShortsWrapper .loginGeneratorThumbnail figure figcaption {
    color: #24282f;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif !important;
}

.loginGenerateShortsWrapper .loginGeneratorThumbnail figure figcaption span {
    color: #979797;
}

.loginGenerateShortsWrapper .loginProcessingTimeframe label,
.loginGenerateShortsWrapper .loginPreferedShortsLength label {
    color: #979797;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: "Poppins", sans-serif !important;
    margin: 0;
}

.loginGenerateShortsWrapper .loginProcessingTimeframe .shortsTimeframe {
    margin: 20px 0 20px;
    position: relative;
}

.loginGenerateShortsWrapper .loginProcessingTimeframe .shortsTimeframe .sortsTimeFrameLine {
    border-radius: 4px;
    background: var(--MainColor, #3782f7);
    height: 2px;
    width: 100%;
}

.loginGenerateShortsWrapper .loginProcessingTimeframe .timeIncreaseDecrease {
    width: 4px;
    height: 18px;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.loginGenerateShortsWrapper .loginProcessingTimeframe .shortsTimeFrameStart {
    left: 0;
}

.loginGenerateShortsWrapper .loginProcessingTimeframe .shortsTimeFrameEnd {
    right: 0;
}

.loginGenerateShortsWrapper .loginProcessingTimeframe .shortsTimeFrameTiming {
    display: flex;
    justify-content: space-between;
}

.loginGenerateShortsWrapper .loginProcessingTimeframe .shortsTimeFrameTiming p.shortsTiming {
    color: #979797;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    font-family: "Poppins", sans-serif !important;
    line-height: normal;
}

.loginGenerateShortsWrapper .loginPreferedShortsLength {
    margin-top: 40px;
}

.loginGenerateShortsWrapper .loginPreferedShortsLength .preferredShortsLength {
    display: flex;
    margin: 12px 0 0;
    padding: 0;
    grid-gap: 4px;
    align-items: center;
    flex-wrap: wrap;
}

.loginGenerateShortsWrapper .loginPreferedShortsLength .preferredShortsLength .shortLength {
    position: relative;
}

.loginGenerateShortsWrapper .loginPreferedShortsLength .preferredShortsLength .shortLength input[type="radio"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.loginGenerateShortsWrapper .loginPreferedShortsLength .preferredShortsLength .shortLength label {
    border-radius: 9px;
    padding: 9px 8px;
    background: #f0f1f2;
    color: #636363;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    border-style: none;
}

.loginGenerateShortsWrapper .loginPreferedShortsLength .preferredShortsLength .shortLength input[type="radio"]:checked+label,
.loginGenerateShortsWrapper .loginPreferedShortsLength .preferredShortsLength .shortLength input[type="radio"]:hover+label {
    background: #f0f6ff;
    color: #3782f7;
}

/* Shorts Maker Login Generate Shorts Section CSS Are Close Here */
/* AI Analyzing Your Video Success CSS Are Start Here */
.shortsAIAnalyzingSuccessWrapper {
    padding: 120px 0;
}

.shortsAIAnalyzingSuccessWrapper .shortsAiAnalyzingContainer {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.shortsAIAnalyzingSuccessWrapper .shortsAiHeading {
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    font-family: Helvetica !important;
    line-height: 60.5px;
    margin: 0;
}

.shortsAIAnalyzingSuccessWrapper .shortsAiSubHeading {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    font-family: Helvetica !important;
    margin: 8px 0 50px;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo {
    max-width: 435px;
    margin: 48px auto 0;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    padding: 0;
    border-style: none !important;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo input[type="range"]::-webkit-slider-runnable-track {
    height: 6px;
    background: #ecf3ff;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    width: 15px;
    background-color: #1679ff;
    border: 1px solid #1679ff;
    box-shadow: -407px 0 0 400px #1679ff;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo output,
.shortsAIAnalyzingSuccessWrapper .fetchingVideo .fetchingVideoContent {
    color: #979797;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: "Poppins", sans-serif !important;
    margin-top: 16px;
    padding: 0;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo .fetchingVideoContent {
    margin-top: 45px;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo .shortsAiCancel {
    border-radius: 10px;
    background: #f1f2f4;
    width: 271px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    margin: 74px auto 0;
    color: #626262;
    text-align: center;
    font-family: Helvetica !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    transition: all 1s ease;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo .shortsAiCancel:hover {
    background: #1679ff;
    color: #fff;
}

/* AI Analyzing Your Video Success CSS Are Close Here */
/* Shorts Popup Section CSS Start Here */
.shortsMakerPopupWrapper .fade.ss_modal.modal {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 0 !important;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal::-webkit-scrollbar {
    display: none;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricing-tab-card {
    background-color: #f8f8f8;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricing-tab-card.pro {
    background-color: #f0f6ff !important;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .modal-dialog {
    max-width: 875px;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content {
    max-width: 100%;
    margin: 0;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .tab-row {
    margin: 0;
    grid-gap: 0 16px;
    flex-wrap: wrap;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .shortsPopRow {
    display: grid;
    grid-template-columns: 35% 65%;
    padding: 0 20px;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .tab-row>* {
    width: calc(50% - 16px);
    flex: none;
    margin: 0;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .nav.nav-tabs {
    margin: 0 auto 30px;
    max-width: 175px;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .nav.nav-tabs li.nav-item button {
    padding: 8px 10px;
}

.shortsMakerPopupWrapper .ModalWrapper .pricing-tab-card.free {
    display: block !important;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .nav.nav-tabs li.nav-item .save-percent {
    position: absolute;
    top: -24px;
    right: 0;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricingShortsClipWrapper img {
    max-width: 82px;
    margin-top: 60px;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricingShortsClipWrapper .shortsClipHeading {
    margin: 30px 0 56px;
    font-family: "SF Compact Display" !important;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricingShortsClipWrapper .shortsClipContent {
    color: #7d7d7d;
    font-family: "SF Compact Display" !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
    width: 80%;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .nav.nav-tabs li.nav-item .save-percent span {
    color: #fe8000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    font-family: "Poppins", sans-serif !important;
    line-height: normal;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .planLevelWrapper .pricing-list {
    max-width: 100%;
}

.shortsSeeFeature {
    color: var(--Grey7, #636363);
    text-align: center;
    font-family: "SF Compact Display" !important;
    font-family: SF Compact;
    font-size: 12px;
    font-style: normal;
    font-weight: 457;
    line-height: 134.48%;
    margin: 15px 0;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .modal-dialog .modal-content .pricing-heading h2 {
    font-weight: 500;
    font-size: 22px;
}

.shortsMakerPopupWrapper .pricing-tab-card .pricing-btn a.button {
    font-weight: 400;
}

.shortsMakerPopupWrapper .pricing-tab-card.free .pricing-btn a.button {
    background-color: #e2e3e4;
    color: #fff;
}

.shortsMakerPopupWrapper .pricingPriceContent {
    min-height: 95px;
}

/* Shorts Popup Section CSS Close Here */
/* Shorts Page Responsive CSS Are Start Here */
@media (min-width: 768px) {
    .shortsSectionWrapper .shorts-container {
        max-width: 100%;
        padding: 0 50px;
    }
}

@media (min-width: 1200px) {
    .shortsSectionWrapper .shorts-container {
        max-width: 1140px;
        padding: 0 16px;
    }
}

@media (max-width: 1199px) {

    .shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .left_section h1,
    .shortsSectionWrapper h2 {
        font-size: 40px;
        line-height: 50px;
    }

    .shortsSectionWrapper .underHeroWrapper .underHeroheading h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper {
        padding: 60px 0;
        margin-top: 60px;
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper .videoThumbContent h2 {
        font-size: 36px;
        line-height: 45px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner .StreamlineShortsColumn {
        padding: 50px 25px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .StreamlineShortsColumn .title {
        font-size: 24px;
        line-height: 32px;
    }

    .shortsSectionWrapper .aiGenerateShortsWrapper {
        margin-top: 60px;
        padding: 60px 0;
    }

    .shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .planTitle {
        font-size: 20px;
        line-height: 1;
    }

    .shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .price {
        font-size: 26px;
    }

    .shortsSectionWrapper .faqWrapper,
    .shortsSectionWrapper .freeSapmleClipsWrapper {
        margin-top: 60px;
    }

    .shortsSectionWrapper .freeSapmleClipsWrapper .freeSampleClipsInner {
        padding: 60px 0;
    }

    .successSectionWrapper .successSectionHeading h2 {
        font-size: 36px;
        line-height: 50px;
    }

    .successSectionWrapper .successSectionSubHead h4 {
        font-size: 22px;
        line-height: 26px;
    }
}

@media (max-width: 991px) {
    .shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper {
        grid-gap: 30px;
    }

    .shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .left_section h1,
    .shortsSectionWrapper h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .shortsSectionWrapper .formShortcode .formInputFields .submitField {
        max-width: 135px;
    }

    .shortsSectionWrapper .formShortcode .formInputFields .submitField input[type="submit"] {
        font-size: 14px;
    }

    .shortsSectionWrapper .underHeroWrapper .underHeroheading h2 {
        font-size: 28px;
        line-height: 32px;
    }

    .shortsSectionWrapper .underHeroWrapper .underHeroImgGroup {
        grid-gap: 20px;
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper .videoThumbContent h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper .videoThumbContent p {
        font-size: 16px;
        line-height: 26px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner {
        grid-gap: 0 20px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .StreamlineShortsColumn .title {
        font-size: 20px;
        line-height: 28px;
        margin: 0 0 15px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner .StreamlineShortsColumn {
        padding: 30px 15px;
    }

    .shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn {
        padding: 30px;
    }

    .shortsSectionWrapper .freeSapmleClipsWrapper .formShortcode {
        max-width: 500px;
    }

    .successSectionWrapper .successSectionHeading h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .successSectionWrapper .successSectionSubHead h4 {
        font-size: 18px;
    }

    .successSectionWrapper .successSectionContent {
        padding: 30px;
    }

    .shortsSectionWrapper .underHeroWrapper .underHeroImgGroup .underHeroImage {
        width: calc(33.3% - 20px);
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .shortsPopRow {
        grid-template-columns: 1fr;
        grid-gap: 40px 0;
        padding: 0;
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricingShortsClipWrapper img {
        margin-top: 0;
    }

    .shortsMakerPopupWrapper .pricingShortsClipWrapper {
        text-align: center;
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricingShortsClipWrapper .shortsClipHeading {
        margin: 10px 0 20px;
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content {
        max-width: 100%;
        margin: 0 !important;
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .modal-dialog .modal-content {
        padding: 30px 10px 20px;
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricingShortsClipWrapper .shortsClipContent {
        margin: 0 auto;
    }
}

@media (max-width: 767px) {
    .planLevelWrapper {
        border-radius: 30px;
        padding: 30px;
    }

    .planLevelWrapper h3 {
        font-size: 26px;
    }

    .shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .left_section {
        flex: none;
    }

    .shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .right_section {
        width: 100%;
    }

    .shortsSectionWrapper .underHeroWrapper .underHeroImgGroup .underHeroImage {
        width: calc(50% - 20px);
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper {
        grid-gap: 30px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner {
        grid-gap: 20px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner .StreamlineShortsColumn {
        flex: none;
        width: calc(50% - 20px);
    }

    .shortsSectionWrapper *:not(.aiGenerateShortsRow *) {
        width: 100%;
    }

    .loginGenerateShortsWrapper .loginGeneratorShortsContainer {
        max-width: 480px;
        margin: 0 auto;
    }

    .shortsAIAnalyzingSuccessWrapper .shortsAiAnalyzingContainer {
        max-width: 480px;
    }

    .shortsAIAnalyzingSuccessWrapper .shortsAiHeading {
        font-size: 30px;
        line-height: 36px;
    }
}

@media (max-width: 575px) {
    .loginGenerateShortsWrapper .loginGeneratorShortsContentBox {
        padding: 20px;
    }

    .shortsSectionWrapper .underHeroWrapper .underHeroImgGroup .underHeroImage {
        width: 100%;
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper>* {
        flex: none;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner .StreamlineShortsColumn {
        width: 100%;
    }

    .shortsSectionWrapper .aiGenerateShortsWrapper .aiGenerateShortsRow {
        flex-wrap: wrap;
        grid-gap: 60px;
    }

    .shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn {
        flex: none;
        width: 100%;
    }

    .shortsSectionWrapper .formShortcode .formInputFields .inputField {
        flex: none;
    }

    .page-template-template-shorts header#masthead div#site-logo img {
        max-width: 200px;
    }

    .page-template-template-shorts header#masthead {
        align-items: center;
    }

    .shortsSectionWrapper .freeSapmleClipsWrapper .freeSampleClipsInner {
        padding: 30px;
    }

    .shortsSectionWrapper .formShortcode .formInputFields .inputField input {
        padding: 0 140px 0 15px;
    }

    .shortsSectionWrapper .faqWrapper .feature-faq h4 {
        margin: 0;
    }

    .loginGenerateShortsWrapper .loginGeneratorShortsContainer {
        max-width: 100%;
        padding: 0 16px;
    }

    .loginGenerateShortsWrapper .loginGeneratorShortsContentBox h6.generatorShorts {
        font-size: 14px;
        text-align: center;
    }

    .loginGenerateShortsWrapper .loginGeneratorShortsContentBox .shortsMakersTalkingVideos .worksBestTalkingVideos li {
        font-size: 14px;
    }

    .loginGenerateShortsWrapper .loginGeneratorShortsContentBox .shortsMakersTalkingVideos .worksBestTalkingVideos {
        flex: none;
        width: 100%;
    }

    .loginGenerateShortsWrapper .loginGeneratorShortsContentBox .shortsMakersTalkingVideos {
        grid-gap: 10px;
        margin-top: 10px;
    }

    .shortsAIAnalyzingSuccessWrapper .shortsAiAnalyzingContainer {
        max-width: 100%;
        padding: 0 16px;
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .tab-row>* {
        width: 100%;
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .tab-row {
        grid-gap: 16px 0;
    }

    .shortsMakerPopupWrapper .pricingPriceContent {
        min-height: 100%;
    }
}

@media (max-width: 420px) {
    .shortsSectionWrapper .formShortcode .formInputFields .submitField {
        position: static;
        max-width: 100%;
        transform: translate(0);
        margin-top: 20px;
    }

    .shortsSectionWrapper .formShortcode .formInputFields .inputField input {
        padding: 0 15px;
    }

    .shortsSectionWrapper .formShortcode .formInputFields label#youtubeChannel-error {
        position: static;
    }

    .successSectionWrapper .success-headings_wrapper {
        grid-gap: 20px 0;
        flex-wrap: wrap;
    }
}

/* Shorts Page Responsive CSS Are Close Here */
/* Shorts Page CSS Are Close Here */

/* New css */
.draftSection span {
    font-size: 10px;
}

.logo-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.logo-container>img {
    width: 17%;
    flex: 0 0 auto;
    height: 100px;
    object-fit: contain;
}

.partner_logos_warpper {
    margin-top: 40px;
}

.grey_bg {
    background-color: #f2f5f9;
}

.shortsSectionWrapper>section {
    padding: 80px 0;
}

.shortsSectionWrapper .heroSection {
    padding-top: 15px;
}

.multipleVideosThumbInner {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 30px 20px;
    margin-top: 40px;
}

.multipleVideosThumbInner>div {
    flex: 0 0 auto;
    width: calc(50% - 14px);
}

.videosThumbImage {
    background-color: #000;
    border-radius: 20px;
    overflow: hidden;
    aspect-ratio: 16/10.5;
    padding: 5px;
}

.videosThumbImage img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.videoThumbContent h3 {
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 6px;
    color: #1b1a23;
    letter-spacing: 0px !important;
}

.pefectToolsSection .FeedbackSectionWrapper {
    text-align: center;
}

.pefectToolsSection {
    text-align: center;
}

.pefectToolsSection .PerfectToolsHeading p {
    max-width: 824px;
    display: inline-block;
    margin-bottom: 0;
}

.FeedbackContentRepeat {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
}

.FeedbackContentRepeat>div {
    flex: 0 0 auto;
    width: calc(50% - 20px);
    text-align: left;
}

.FeedbackContentRepeat h3 {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
}

.FeedbackContentRepeat p {
    font-size: 18.6px;
    line-height: 1.6;
}

.pricing-sec {
    background-color: #ecf2fe;
    margin-top: 80px;
    padding: 80px 50px;
    border-radius: 20px;
}

.pricing-sec h2 {
    text-align: center;
}

.pricing-sec .pricing-tab-content .tab-row .pricing-tab-card {
    background-color: #fff !important;
}

.pricing-sec .pricing-tab-content.ssembleYtDownloaderPrice {
    margin-bottom: 0 !important;
}

.shortsSectionWrapper .freeSapmleClipsWrapper {
    margin-top: 0;
}

.shortsSectionWrapper .freeSapmleClipsWrapper .freeSampleClipsInner {
    padding: 0;
}

.gradiant_bg {
    background: linear-gradient(0, #f2f5f9 0.06%, rgba(242, 245, 249, 0) 88.95%);
}

.aishorts-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.aishorts-row:nth-child(2n + 1) {
    flex-direction: row-reverse;
}

.aishorts-row>div {
    padding: 0 15px;
    text-align: left;
    width: 50%;
    flex: 0 0 auto;
}

.aishorts-row .aishorts-content h3 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 24px;
    letter-spacing: 0;
}

.aishorts-row .aishorts-img img {
    display: inline-block;
    max-width: 450px;
    height: auto;
}

.aishorts-row:nth-child(2) {
    margin-bottom: 60px;
}

.aishorts-sec h2 {
    margin-bottom: 100px;
}

.aishorts-sec {
    padding: 110px 0 0;
}

.aishorts-row .aishorts-img img {
    max-width: 440px;
    width: 100%;
}

.shortsSectionWrapper .aishorts-content p {
    margin-bottom: 0;
}

.TutorialSectionWrapper {
    text-align: center;
}

.TutorialSectionWrapper .tutorialIframe iframe {
    width: 100%;
    max-width: 962px;
    aspect-ratio: 16/9;
    height: auto;
    border-radius: 12px;
    margin-top: 50px;
}

.aishorts-row:nth-child(2n + 1)>div.aishorts-img {
    text-align: end;
}

.FeedbackSectionWrapper .FeedbackHeading {
    margin-bottom: -70px;
    margin-top: 45px;
}

.feedback-sec {
    overflow: hidden;
}

.feedback-sec .FeedbackThumbInner {
    margin-left: -9%;
    margin-right: -9%;
    width: auto;
}

.gradiant_bg {
    padding-top: 94px;
    padding-bottom: 94px;
}

.PerfectToolsHeading p {
    font-size: 16px;
}

.pricing-tab-content .nav-tabs {
    border-radius: 24px;
    background-color: #fafafa;
}

.pricing-tab-content .nav-tabs .nav-item button {
    font-weight: 400;
}

.pricing-tab-content .nav-tabs .nav-item button.tab.active {
    font-weight: 500;
    border: 1px solid #ecf2fe;
    box-shadow: -1px 3px 6px rgb(236, 242, 254);
    border-radius: 27px;
    padding: 12px 63px;
}

.shortsSectionWrapper .formShortcode {
    padding-right: 31px;
}

@media (max-width: 991px) {
    .TutorialSectionWrapper .tutorialIframe iframe {
        margin-top: 30px;
    }

    .videoThumbContent h3 {
        font-size: 20px;
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper {
        padding-bottom: 0;
    }

    .FeedbackContentRepeat h3 {
        font-size: 20px;
    }

    .FeedbackContentRepeat p {
        font-size: 16px;
        margin-top: 12px;
    }

    .aishorts-sec {
        padding: 70px 0 0;
    }

    .aishorts-sec h2 {
        margin-bottom: 60px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper {
        padding-top: 50px;
    }

    .aishorts-row .aishorts-content h3 {
        margin-bottom: 10px;
        font-size: 24px;
    }

    .shortsSectionWrapper p {
        margin-top: 15px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .StreamlineShortsColumn .description {
        font-size: 16px;
    }

    .pricing-sec {
        padding: 36px 34px;
    }

    .shortsSectionWrapper>section {
        padding: 60px 0;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner .StreamlineShortsColumn .description {
        margin-bottom: 0;
    }

    .gradiant_bg {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .FeedbackSectionWrapper .FeedbackHeading {
        margin-bottom: -48px;
        margin-top: 31px;
    }
}

@media (max-width: 767px) {
    .FeedbackSectionWrapper .FeedbackHeading {
        margin-bottom: -20px;
        margin-top: 30px;
    }

    .logo-container>img {
        width: 33% !important;
    }

    .shortsSectionWrapper>section {
        padding: 50px 0;
    }

    .shortsSectionWrapper h2 {
        font-size: 28px;
    }

    .multipleVideosThumbInner {
        margin-top: 20px;
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper {
        padding-top: 0;
    }

    .FeedbackContentRepeat p {
        margin-bottom: 0;
    }

    .aishorts-sec {
        padding: 50px 0 0;
    }

    .aishorts-sec h2 {
        margin-bottom: 20px;
    }

    .aishorts-content {
        margin-top: 21px;
    }

    .aishorts-row .aishorts-content h3 {
        margin-bottom: 0;
    }

    .aishorts-row .aishorts-content h3+p {
        margin-top: 7px;
    }

    .aishorts-row:not(:last-child),
    .aishorts-row:nth-child(2) {
        margin-bottom: 41px;
    }

    .pricing-sec {
        margin-top: 20px;
    }
}




/* Only for test */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.2/font/bootstrap-icons.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/antd/4.21.7/antd.min.css");
/* Header CSS Start Here */
@media (min-width: 1200px) {
    .shortsMakerReactjs header#masthead.site-header {
        max-width: 1184px !important;
    }
}


.nav-link.active {

    font-weight: 500;
}

a.nav-link {
    font-size: 16px;
}

h3 {
    font-size: 27px;
}

h1 {
    font-size: 45px;
}

h2 {
    font-size: 35px;
}



/* login button styles */

a.disconnectbtn {
    position: absolute;
    right: 40px;
    z-index: 9999;
}

button.ytConnect img {
    width: 100px;
    margin: 0 auto;
}

button.InstaConnect img,
button.tiktokConnect img {
    width: 30px;
    margin: 0 auto;
}

button.ytConnect {
    font-weight: 500;
}


/* end of login button styles */

/* Compare list styles start here */
.comparisonMainHeadWrapper .mainFeatureHeading {
    display: flex;
}

.subFeaturesWrapper .subFeaturesRow {
    display: flex;
}

.mainInner {
    width: 25%;
}

.subFeaturesWrapper {
    width: 75%;
}

.subFeatureTitle {
    width: 70%;
}

.subFeaturessembleTick {
    width: 10%;
}

.subFeatureOtherTick {
    width: 10%;
}

.compareListingWrapper p {
    padding: 0 !important;
    margin: 15px !important;
}

/* compare list styles end here */

.page-template-template-shorts-react .vlog_tool_wrapper {
    padding-bottom: 0 !important;
}

.shortsSectionWrapper p {
    color: #1b1a23;
    font-size: 18px;
    line-height: 1.6;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 20px 0 40px;
    letter-spacing: 0px;
}

.underHeroheading p {
    text-align: center;
    font-size: 16px;
}

span.ssregular_pricess_strike {
    text-decoration: line-through;
    font-size: 28px;
    font-weight: 700;
}

span.ss_sale_price {
    color: #ff5c00;
}

.shortsMakerButtonWrap .spinner-border {
    margin-right: 10px;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    pointer-events: none !important;
    opacity: 0.25 !important;
}

.main-navigation .addtionalButtonsData {
    display: flex;
    align-items: center;
    grid-gap: 53px;
    margin-right: 24px;
}

.main-navigation .addtionalButtonsData .ss_plan_mintues {
    display: flex;
    align-items: center;
    grid-gap: 0 10px;
}

.main-navigation .addtionalButtonsData .ss_plan_mintues .progress {
    width: 58px;
    height: 5px;
    margin: 0;
}

.shortsMakerReactjs .main-navigation {
    justify-content: flex-end;
    margin: 20px 0px 0px 0px;
}

.top-bar #top-menu {
    padding-right: 20px;
}

.progress.bg-success {
    background-color: #1985534d !important;
}

.progress.bg-danger {
    background-color: #ffd3d3 !important;
}

.main-navigation .addtionalButtonsData .ss_plan_mintues .planTitle {
    color: #7d7d7d;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
}

.main-navigation .addtionalButtonsData .ss_plan_mintues .planTime {
    font-size: 11px;
    font-weight: 400;
    line-height: 1;
}

.loading-sec.loadingSpinnerShorts {
    text-align: center;
    margin: 0;
    margin-top: 40px;
}

.main-navigation .addtionalButtonsData .ss_plan_mintues .planTime.danger {
    color: #ff2424;
}

.main-navigation .addtionalButtonsData .ss_plan_mintues span.planTime.success {
    color: #198754;
}

.FeedbackContentRepeat h3,
.vidoesThumbWrapper h3 {
    font-weight: 700 !important;
}

.aishorts-content h3 a {
    color: #000;
}

.main-navigation .addtionalButtonsData button.ss_gradient {
    border-radius: 6px;
    background: linear-gradient(93deg,
            #ff603c 0%,
            #8558fc 73.19%,
            #0116d1 113.18%);
    color: #fff;
    text-align: center;
    font-family: "SF Compact Display" !important;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.32px;
    min-height: 40px;
    min-width: 174px;
    line-height: 1;
    border-style: none;
    padding: 0;
    transition: all 0.5s ease;
}

ul.worksBestTalkingVideos {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-auto-flow: column;
}

/* Header CSS Close Here */
.accordion-collapse.collapse.show {
    visibility: visible;
}

.page-template-template-shorts-react .ModalWrapper .pricing-tab-card.free {
    display: block !important;
}

/* Non Logged In View CSS Start Here */

span.lazy-load-image-background.lazy-load-image-loaded {
    max-width: 100%;
}

.shortsSectionWrapper .formShortcode .formInputFields input.youtubeChannel {
    border-radius: 8px;
    background-color: #f1f2f4;
    border: 1px solid #f1f2f4;
    box-shadow: none;
    min-height: 48px;
    padding: 0 180px 0 15px;
    font-family: "Noto Sans", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.shortsSectionWrapper .formShortcode .formInputFields input.youtubeChannel::placeholder {
    color: #c3c3c3;
}

.shortsSectionWrapper .formShortcode .formInputFields .shortsMakerButtonIsClicked {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    border-radius: 8px;
    opacity: 1;
    background-color: #3782f7;
    width: 168px;
    color: #fff;
    text-align: center;
    font-family: Helvetica !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    border-style: none;
    transition: all 0.5s ease;
    cursor: pointer;
}

.shortsSectionWrapper .formShortcode .formInputFields .shortsMakerButtonIsClicked:hover {
    background-color: #0f6dec;
}

.shortsSectionWrapper .formShortcode .formInputFields label#youtubeChannel-error {
    bottom: -28px !important;
    font-family: "Noto Sans", sans-serif !important;
}

.shortsSectionWrapper .faqWrapper h4 {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 4px;
}

.shortsSectionWrapper .faqWrapper .accordion-item {
    border-style: none;
}

.shortsSectionWrapper .faqWrapper .accordion-item:not(:last-child) {
    border-bottom: 1px solid #e8e8e8;
}

.shortsSectionWrapper .faqWrapper .accordion-item .accordion-header .accordion-button {
    border-style: none;
    box-shadow: none;
    background-color: transparent;
    padding: 20px 0;
    color: #000;
    font-size: 18px;
    font-family: "Canva Sans" !important;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
}

.shortsSectionWrapper .faqWrapper .accordion-item .accordion-header .accordion-button:hover {
    color: #3782f7;
}

.accordion-button::after {
    transform: rotate(180deg);
}

.shortsSectionWrapper .faqWrapper .accordion-item .accordion-body {
    padding: 0 0 15px 0;
    color: #6a6a6a;
    font-family: "Canva Sans" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.freeSapmleClipsWrapper .formShortcode .formInputFields input.youtubeChannel {
    background-color: #fff;
}

@media (max-width: 991px) {
    .shortsSectionWrapper .formShortcode .formInputFields .shortsMakerButtonIsClicked {
        width: 135px;
        font-size: 14px;
    }

    .shortsSectionWrapper .formShortcode .formInputFields input.youtubeChannel {
        padding: 0 140px 0 10px;
    }
}

@media (max-width: 420px) {
    .shortsSectionWrapper .formShortcode .formInputFields .shortsMakerButtonIsClicked {
        width: 100%;
        position: static;
        margin-top: 10px;
        padding: 12px;
    }

    .shortsSectionWrapper .formShortcode .formInputFields input.youtubeChannel {
        padding: 0 10px;
    }
}

/* Non Logged In View CSS Clsoe Here  */
/* Logged In View CSS Start Here  */
.loginGenerateShortsWrapper .formShortcode .formInputFields input.youtubeChannel {
    box-shadow: none;
}

.loginGenerateShortsWrapper .formShortcode .formInputFields .submitField .shortsMakerButtonIsClicked {
    border-radius: 10px;
    background-color: #3782f7;
    border-style: none;
    width: 271px;
    height: 48px;
    color: #fff;
    text-align: center;
    display: block;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin: 40px auto;
    transition: all 0.7s ease;
}

.loginGenerateShortsWrapper .formShortcode .formInputFields .submitField .shortsMakerButtonIsClicked:hover {
    background-color: #0f6dec;
}



.loginGenerateShortsWrapper .loginGeneratorShortsContentBox .shortsMakersTalkingVideos .worksBestTalkingVideos li {
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center left;
    padding-left: 28px;
    margin-bottom: 5px;
}

.loginGenerateShortsWrapper .shortsRangeError {
    color: #f00000;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    font-family: "Poppins", sans-serif !important;
    line-height: 1.5;
    max-width: 435px;
    margin: 0 auto;
    padding-top: 20px;
}

.page-template-template-shorts-react .ss_modal.modal .pricing-heading {
    display: flex;
    align-items: center;
}

.page-template-template-shorts-react .fade.ss_modal.modal .modal-dialog .modal-content .pricing-heading h2 {
    font-size: 22px;
    font-weight: 500;
}

.page-template-template-shorts-react .fade.ss_modal.modal .modal-dialog .modal-content .pricing-btn {
    padding: 0;
}

.tools-heading .shortMaker-heading {
    color: #000;
    text-align: center;
    font-family: Helvetica !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 60.5px;
    margin-bottom: 8px;
    letter-spacing: 0;
}

.tools-heading .shortmakerSub-heading {
    color: #000;
    text-align: center;
    font-family: Helvetica !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 0;
}

.loginGenerateShortsWrapper .youtube-down-con .percentage-text {
    color: #979797;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 40px;
}

.loginGenerateShortsWrapper .youtube-down-con p.shortsLoadingLabel {
    color: #979797;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
}

.loginGenerateShortsWrapper .youtube-down-con .secondary-btn button {
    border-radius: 10px;
    background: #f1f2f4;
    border-style: none;
    width: 271px;
    height: 48px;
    display: block;
    margin: 0 auto;
    transition: all 0.3s ease;
    color: #626262;
    text-align: center;
    font-family: Helvetica !important;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
}

.loginGenerateShortsWrapper .shortsImageWrapper {
    margin-top: 50px;
}

.loginGenerateShortsWrapper .shortsImageWrapper img {
    width: 435px;
    border-radius: 8px;
    display: block;
    margin: 0 auto;
}

.loginGenerateShortsWrapper .youtube-down-con .secondary-btn button:hover {
    background: #ccc;
    color: #fff;
}

.loginGenerateShortsWrapper .loginGeneratorThumbnail.shortsChildContainer figure img {
    border-radius: 8px;
}

@media (max-width: 575px) {
    .loginGenerateShortsWrapper .formShortcode .formInputFields .submitField .shortsMakerButtonIsClicked {
        width: 100%;
        font-size: 16px;
        height: 40px;
    }

    .loginGenerateShortsWrapper .youtube-down-con .secondary-btn button {
        width: 100%;
    }

    .tools-heading .shortMaker-heading {
        font-size: 30px;
        line-height: 36px;
    }

    .tools-heading .shortmakerSub-heading,
    .loginGenerateShortsWrapper .youtube-down-con p.shortsLoadingLabel {
        font-size: 20px;
        line-height: 26px;
    }

    .loginGenerateShortsWrapper .loginGeneratorShortsContainer .container {
        padding: 0;
    }
}

/* Logged In View CSS Close Here  */

/* Shorts Popup CSS Start Here */
.page-template-template-shorts-react .fade.ss_modal.modal::-webkit-scrollbar {
    display: none;
}

.page-template-template-shorts-react .fade.ss_modal.modal {
    padding-right: 0 !important;
}

.page-template-template-shorts-react .fade.ss_modal.modal .modal-dialog {
    max-width: 875px;
    border-radius: 16px;
}

.page-template-template-shorts-react .fade.ss_modal.modal .modal-dialog .modal-content {
    padding: 30px 40px 30px 25px;
}

.page-template-template-shorts-react .ModalWrapper .pricing-tab-content.ssembleYtDownloaderPrice {
    margin: 15px 0 0 !important;
    max-width: 100%;
}

.page-template-template-shorts-react .ModalWrapper {
    display: grid;
    grid-template-columns: 35% 65%;
}

.page-template-template-shorts-react .ModalWrapper .leftContent {
    margin-top: 100px;
}

.page-template-template-shorts-react .ModalWrapper .ssPricingTableWrapper {
    padding-top: 0;
}

.page-template-template-shorts-react .ModalWrapper .pricing-tab-content .nav.nav-tabs {
    margin: 0 auto 30px;
    max-width: 175px;
}

.page-template-template-shorts-react .ModalWrapper .pricing-tab-content .nav.nav-tabs li.nav-item button {
    padding: 9px 17px;
}

.page-template-template-shorts-react .ModalWrapper .pricing-tab-content .nav.nav-tabs li.nav-item .save-up span {
    background-color: transparent;
    position: static !important;
    padding: 0 !important;
    width: fit-content;
    max-width: 100%;
    color: #fe8000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 0;
    display: inline;
}

.page-template-template-shorts-react .ModalWrapper .pricing-tab-content .nav.nav-tabs li.nav-item .save-up span::before {
    display: none;
}

.page-template-template-shorts-react .ModalWrapper .pricing-tab-content .nav.nav-tabs li.nav-item .save-up {
    right: 0;
    left: auto;
    position: absolute;
    transform: translatex(0);
    width: fit-content;
    top: -24px;
    text-align: right;
}

.page-template-template-shorts-react .ModalWrapper .leftContent .shortsClipsWrapper img {
    max-width: 62px;
}

.page-template-template-shorts-react .ModalWrapper .leftContent .shortsClipsWrapper .shortsClipHeading {
    color: #000;
    font-family: "SF Compact Display" !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    margin: 20px 0 50px;
}

.page-template-template-shorts-react .ModalWrapper .leftContent .shortsClipsWrapper .shortsClipContent {
    color: #7d7d7d;
    font-family: "SF Compact Display" !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 457;
    line-height: 138%;
    width: 80%;
}

.page-template-template-shorts-react .fade.ss_modal.modal .modal-dialog .ssemblePricingWrapperInner {
    min-height: 85px;
}

/******************/
.customRadioBtnGroup {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
}

.customRadioBtns.form-check {
    position: relative;
    padding: 4px 4px 0px 0px;
}

.customRadioBtns input[type="radio"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.customRadioBtns label {
    border-radius: 9px;
    padding: 9px 8px;
    background: #f0f1f2;
    color: #636363;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    border-style: none;
}

.customRadioBtns input[type="radio"]:checked+label,
customRadioBtns input[type="radio"]:hover+label {
    background: #f0f6ff;
    color: #3782f7;
}

/* Popup Responsive */
@media (max-width: 991px) {
    .page-template-template-shorts-react .ModalWrapper {
        grid-template-columns: 1fr;
        grid-gap: 40px 0;
    }

    .page-template-template-shorts-react .ModalWrapper .leftContent {
        margin-top: 0;
        text-align: center;
    }

    .page-template-template-shorts-react .ModalWrapper .leftContent .shortsClipsWrapper .shortsClipHeading {
        margin: 15px 0 30px;
    }

    .page-template-template-shorts-react .ModalWrapper .leftContent .shortsClipsWrapper .shortsClipContent {
        width: 100%;
        margin: 0;
    }
}

@media (max-width: 575px) {
    .page-template-template-shorts-react .fade.ss_modal.modal .modal-dialog .modal-content {
        padding: 30px 20px;
    }

    .page-template-template-shorts-react .fade.ss_modal.modal .ssPricingTableWrapper .pricing-tab-card .pricing-list ul {
        margin: 0;
    }

    .page-template-template-shorts-react .fade.ss_modal.modal .modal-dialog .ssemblePricingWrapperInner {
        min-height: fit-content;
    }
}

@media (max-width: 360px) {
    .page-template-template-shorts-react .ModalWrapper .pricing-tab-content.ssembleYtDownloaderPrice .pricing-tab-card {
        margin: 16px 0 !important;
    }
}

/* Shorts Popup CSS Close Here */
/* Pro User Popup CSS start Here */
.fade.ss_modal.modal.proUserPopup .modal-dialog .modal-content {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.6);
    min-height: 80px;
    padding: 18px 15px;
    max-width: 600px;
}

.fade.ss_modal.modal.proUserPopup .modal-dialog .modal-content .modal-body p {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145.053%;
    font-family: "Noto Sans", sans-serif !important;
    text-align: center;
}

.fade.ss_modal.modal.proUserPopup .modal-dialog .modal-content .modal-header {
    top: 5px;
    right: 5px;
}

.fade.ss_modal.modal.proUserPopup .modal-dialog .modal-content .modal-header button.btn-close {
    filter: brightness(0) invert(1);
    opacity: 1;
}

.fade.ss_modal.modal.proUserPopup .modal-dialog .modal-content .modal-body p a {
    color: #fff;
    font-weight: 700;
    text-decoration: underline;
}

.fade.ss_modal.modal.proUserPopup .modal-dialog .modal-content .modal-body p a:hover {
    color: #0d6efd;
}

/* Pro User Popup CSS Close Here */

/* Shorts Page CSS Are Start Here */
/* Shorts Page Header CSS Are Start Here */
.page-template-template-shorts header#masthead {
    justify-content: space-between;
    max-width: 1140px !important;
}

.page-template-template-shorts header#masthead #top-menu {
    display: flex;
    align-items: center;
}

.page-template-template-shorts header#masthead>* {
    flex: 1;
    float: none;
}

.page-template-template-shorts header#masthead .main-navigation {
    justify-content: flex-end;
}

.page-template-template-shorts header#masthead div#site-logo img {
    max-width: 250px;
}

/* Shorts Page Header CSS Are Close Here */
/* Shorts Page Hero CSS Are Start Here */

.submitField.submitting:before {
    content: "";
    width: 25px;
    height: 25px;
    border: 4px solid #fff;
    border-bottom-color: #ff3d00;
    border-radius: 50%;
    display: inline-block;
    animation: rotation 1s linear infinite;
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    z-index: 55;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.submitField.submitting {
    position: relative;
}

.submitField.submitting input[type="submit"] {
    font-size: 0px !important;
    opacity: 0.3;
}

.shortsSectionWrapper {
    padding: 10px 0 0;
}

.shortsSectionWrapper .shorts-container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 16px;
}

.shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    flex-wrap: wrap;
}

.shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .left_section {
    flex: 1;
}

.shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .right_section {
    width: 41.8%;
}

.shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .imageWrapper {
    width: 100%;
}

.shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .left_section h1 {
    color: #1b1a23;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 62px;
    letter-spacing: -2px;
    margin: 0;
}

.shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .left_section p {
    color: #1b1a23;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 20px 0 40px;
    letter-spacing: 0px;
}

.shortsSectionWrapper input:focus {
    outline: none;
}

.shortsSectionWrapper .formShortcode .formFieldsGroup label {
    color: #1b1a23;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin: 0 0 5px;
    display: block;
    text-transform: capitalize;
}

.shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .shortsFormWrappr label.error {
    color: #ff0022;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    position: absolute;
    margin: 5px 0 0;
    bottom: -30px;
    left: 0;
}

.shortsSectionWrapper .formShortcode {
    min-height: 80px;
    position: relative;
}

.shortsSectionWrapper .formShortcode .formInputFields {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.shortsSectionWrapper .formShortcode .formInputFields .inputField {
    flex: 1;
}

.shortsSectionWrapper .formShortcode .formInputFields .submitField {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 165px;
    width: 100%;
    text-align: right;
}

.shortsSectionWrapper .formShortcode .formInputFields .inputField input {
    border-radius: 8px;
    background: #f1f2f4;
    width: 100%;
    min-height: 48px;
    display: block;
    border: 3px solid #f1f2f4 !important;
    padding: 0 170px 0 15px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    font-family: "Noto Sans", sans-serif !important;
}

.shortsSectionWrapper .formShortcode .formInputFields .inputField input::placeholder {
    color: #c3c3c3;
}

.shortsSectionWrapper .formShortcode .formInputFields .submitField input[type="submit"] {
    border-radius: 8px;
    background: #3782f7;
    width: 100%;
    min-height: 48px;
    display: block;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0;
    padding: 0;
    border-style: none;
    box-shadow: none;
    transition: all 0.7s ease;
}

.shortsSectionWrapper .formShortcode .formInputFields .submitField input[type="submit"]:hover {
    background: #0f6dec;
}

/* Shorts Page Hero CSS Are Close Here */
/* Shorts Page Under Hero CSS Are Start Here */

.shortsSectionWrapper .underHeroWrapper .underHeroInner {
    max-width: 850px;
    margin: 0 auto;
}

.shortsSectionWrapper .underHeroWrapper .underHeroheading h2 {
    color: #1b1a23;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4;
}

.shortsSectionWrapper .underHeroWrapper .underHeroImgGroup {
    display: flex;
    justify-content: center;
    grid-gap: 32px;
    flex-wrap: wrap;
    text-align: center;
    padding: 0;
    margin: 0;
}

.shortsSectionWrapper .underHeroWrapper .underHeroImgGroup .underHeroImage {
    width: calc(33.3% - 32px);
}

.shortsSectionWrapper .underHeroWrapper .underHeroImgGroup .underHeroImage img {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

/* Shorts Page Under Hero CSS Are Close Here */
/* Shorts Page Videos Thumb Img CSS Are Start Here */

.multipleVideosThumbnailWrapper {
    text-align: center;
}

.shortsSectionWrapper .multipleVideosThumbnailWrapper {
    background: linear-gradient(180deg,
            #f2f5f9 35.08%,
            rgba(242, 245, 249, 0) 88.95%);
    padding: 100px 0 20px;
    margin-top: 100px;
}

/* .shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 60px;
    flex-wrap: wrap;
} */


.shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper>* {
    flex: 1;
}

.shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper .videoThumbContent h2 {
    color: #1b1a23;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 62px;
    letter-spacing: 0.45px;
    margin: 0;
    text-transform: capitalize;
}

.shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper .videoThumbContent p {
    color: #1b1a23;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin: 0;
}

/* Shorts Page Videos Thumb Img CSS Are Close Here */
/* Shorts Page Streamline shorts making CSS Are Start Here */
.shortsSectionWrapper .straemlineShortsMakingWrapper {
    padding-top: 0px;
    text-align: center;
}

.straemlineShortsMakingWrapper .streamLineShortsheading {
    padding-bottom: 40px;
}

.shortsSectionWrapper h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 62px;
    letter-spacing: 0;
    margin: 0;
}

.shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 0 40px;
}

.shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner .StreamlineShortsColumn {
    flex: 1;
    border-radius: 9px;
    background: #f2f5f9;
    padding: 70px 48px;
    border-radius: 20px;
}

.shortsSectionWrapper .straemlineShortsMakingWrapper .StreamlineShortsColumn .title {
    color: #1b1a23;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0;
    margin: 0 0 30px;
}

.shortsSectionWrapper .straemlineShortsMakingWrapper .StreamlineShortsColumn .description {
    color: #1b1a23;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
}

/* Shorts Page Streamline shorts making CSS Are Close Here */
/* Shorts Page AI-generated shorts CSS Are Start Here */
.shortsSectionWrapper .aiGenerateShortsWrapper {
    margin-top: 100px;
    padding: 100px 0;
    background-color: #f2f5f9;
    text-align: center;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .generateshortsheading {
    max-width: 800px;
    margin: 0 auto 40px;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGenerateShortsRow {
    max-width: 825px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    grid-gap: 0 30px;
    letter-spacing: 0;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn {
    border-radius: 20px;
    background: #fff;
    flex: 1;
    padding: 32px 50px;
    position: relative;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .planTitle {
    color: #24282f;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    line-height: 134.48%;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .price {
    color: #24282f;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 134.48%;
    margin: 12px 0 6px;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn+.aiGeneratesColumn .regularPrice {
    text-decoration: line-through;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .price .salesPrice {
    color: #ff5c00;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .offMessage {
    position: absolute;
    top: -24px;
    right: 0;
    max-width: 132px;
    height: 48px;
    width: 100%;
    border-radius: 20px;
    background-color: #ff5c00;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .offMessage p {
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .shortsPerMonth {
    color: #7d7d7d;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 116%;
    margin-bottom: 40px;
    font-family: "SF Compact Display" !important;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .contactUsLink {
    border-radius: 6px;
    background: #3782f7;
    width: 100%;
    display: block;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 134.48%;
    padding: 14px 0;
    transition: all 0.5s ease;
    font-family: "Noto Sans", sans-serif !important;
}

.shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .contactUsLink:hover {
    background: #0f6dec;
}

/* Shorts Page AI-generated shorts CSS Are Close Here */
.shortsSectionWrapper *:not(.aiGenerateShortsRow *) {
    font-family: Helvetica !important;
}

.shortsSectionWrapper .aiGenerateShortsRow * {
    font-family: "Poppins", sans-serif !important;
}

/* Shorts Page FAQs CSS Are Start Here */
.shortsSectionWrapper .faqWrapper {
    margin-top: 100px;
}

.shortsSectionWrapper .faqWrapper .feature-faq {
    margin: 0 auto;
    max-width: 1140px;
    padding: 0 16px;
}

.shortsSectionWrapper .faqWrapper .feature-faq {
    margin: 0 auto;
}

.shortsSectionWrapper .faqWrapper .feature-faq h4 {
    margin: 0 0 32px;
}

.shortsSectionWrapper .faqWrapper .feature-faq .accordion-header h2 {
    line-height: 26px;
}

.shortsSectionWrapper .faqWrapper .feature-faq .accordion-item .accordion-collapse .accordion-body p {
    font-size: 16px;
    max-width: 960px;
}

/* Shorts Page FAQs CSS Are Close Here */
/* Shorts Page Free Sample Clips CSS Are Start Here */
.shortsSectionWrapper .freeSapmleClipsWrapper {
    margin-top: 100px;
}

.shortsSectionWrapper .freeSapmleClipsWrapper .freeSampleClipsInner {
    border-radius: 20px;
    background: #f2f5f9;
    padding: 100px 0;
}

.shortsSectionWrapper .freeSapmleClipsWrapper .freeSampleHeading {
    text-align: center;
    padding-bottom: 40px;
}

.shortsSectionWrapper .freeSapmleClipsWrapper .formShortcode {
    max-width: 600px;
    margin: 0 auto;
}

.shortsSectionWrapper .freeSapmleClipsWrapper .formShortcode .formInputFields .inputField input {
    background: #fff;
    border: 3px solid #ffffff !important;
}

.shortsSectionWrapper .formShortcode .formInputFields label#youtubeChannel-error {
    color: #ff0033;
    position: absolute;
    bottom: -35px;
    font-weight: 500;
}

/* Shorts Page Free Sample Clips CSS Are Close Here */
/* Shorts Page Success Section CSS Are Close Here */
.successSectionWrapper {
    padding: 120px 0;
}

.successSectionWrapper .shorts-container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 16px;
}

.successSectionWrapper .success-headings_wrapper {
    display: flex;
    align-items: center;
    grid-gap: 0 30px;
}

.successSectionWrapper .success-headings_wrapper .successLoader {
    width: 100%;
    height: 40px;
    max-width: 40px;
    border: 5px solid #787878;
    border-top: 5px solid rgb(216, 216, 216);
    border-radius: 50%;
    animation: reload 1s infinite linear;
}

@keyframes reload {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

.successSectionWrapper .successSectionHeading h2 {
    color: #000;
    font-size: 40px;
    font-weight: 700;
    line-height: 60.5px;
    margin: 0;
}

.successSectionWrapper .successSectionSubHead h4 {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 8px 0 0;
}

.successSectionWrapper .successSectionContent {
    border-radius: 8px;
    background: #f1f2f4;
    padding: 33px 68px;
    margin-top: 30px;
}

.successSectionWrapper .successSectionContent p {
    color: #1b1a23;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
}

.successSectionWrapper .successSectionContent p:not(:last-child) {
    margin: 0 0 18px;
}

/* Shorts Page Success Section CSS Are Close Here */

.ssemble_image_skelton {
    background-color: #e2e5e7;
    background-image: linear-gradient(90deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    border-radius: 20px;
    animation: shine 1s ease infinite;
}

/* Shorts Page Skeleton CSS Are Start Here */
.shortsSekletonWrapper {
    padding: 120px 15px;
    max-width: 600px;
    margin: 0 auto;
}

.shortsSekletonWrapper .shortsSkeleton {
    background-color: #e2e5e7;
    background-image: linear-gradient(90deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    animation: shine 1s ease infinite;
}

@keyframes shine {
    to {
        background-position: right -40px top 0;
    }
}

.shortsSekletonWrapper .shortsSkeletonLabel {
    width: 125px;
    height: 18px;
    border-radius: 4px;
}

.shortsSekletonWrapper .shortsSkeletonInput {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    margin: 8px 0 30px;
}

.shortsSekletonWrapper .shortsSkeletonButton {
    width: 200px;
    height: 48px;
    margin: 0 auto;
    border-radius: 10px;
}

.shortsSekletonWrapper .shortsSkeletonContent {
    width: 100%;
    height: 250px;
    border-radius: 8px;
    margin-top: 50px;
}

/* Shorts Page Skeleton CSS Are Close Here */
/* Shorts Maker Login Generate Shorts Section CSS Are Start Here */


.loginGenerateShortsWrapper .loginGeneratorShortsContainer {
    max-width: 600px;
    margin: 0 auto;
}

.smallWrapper_sc {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 26px;
}

.loginGenerateShortsWrapper .formShortcode label {
    color: #1b1a23;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    font-family: Helvetica !important;
    line-height: 26px;
}

.loginGenerateShortsWrapper .formShortcode label.error {
    color: red;
    font-weight: 600;
    margin: 0;
}

.loginGenerateShortsWrapper .formShortcode .formInputFields input.youtubeChannel::placeholder {
    color: #c3c3c3;
}

.loginGenerateShortsWrapper .formShortcode .formInputFields input.youtubeChannel {
    border-radius: 8px;
    background: #f1f2f4;
    outline: none !important;
    border: 1px solid #f1f2f4;
    padding: 0 14px;
    display: block;
    width: 100%;
    height: 48px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    font-family: "Noto Sans", sans-serif !important;
}

.loginGenerateShortsWrapper .formShortcode .submitField input[type="submit"] {
    border-radius: 10px;
    background: #3782f7;
    width: 250px;
    margin: 40px auto;
    display: block;
    height: 48px;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    font-family: Helvetica !important;
    transition: all 0.5s ease;
    border-style: none;
}

.loginGenerateShortsWrapper .formShortcode .submitField input[type="submit"]:hover {
    background: #0f6dec;
}

.loginGenerateShortsWrapper .loginGeneratorShortsContentBox .loginGeneratorContentBoxInner {
    max-width: 425px;
    margin: 0 auto;
}

.loginGenerateShortsWrapper .loginGeneratorShortsContentBox {
    border-radius: 8px;
    background: #f8f8f8;
    padding: 20px 30px 30px;
}

.loginGenerateShortsWrapper .loginGeneratorShortsContentBox h6.generatorShorts {
    color: #1b1a23;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: Helvetica !important;
    margin: 0;
}

.loginGenerateShortsWrapper .loginGeneratorShortsContentBox .shortsMakersTalkingVideos {
    display: flex;
    justify-content: space-between;
    grid-gap: 0 40px;
    margin-top: 40px;
    flex-wrap: wrap;
}

.loginGenerateShortsWrapper .loginGeneratorShortsContentBox .shortsMakersTalkingVideos .worksBestTalkingVideos {
    margin: 0;
    padding: 0;
    flex: 1;
}

.loginGenerateShortsWrapper .loginGeneratorShortsContentBox .shortsMakersTalkingVideos .worksBestTalkingVideos li {
    color: #1b1a23;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: Helvetica !important;
}

.loginGenerateShortsWrapper .loginGeneratorThumbnail.shortsChildContainer {
    text-align: center;
    margin: 40px auto;
}

.loginGenerateShortsWrapper .shortsChildContainer {
    max-width: 435px;
    margin: 0 auto;
}

.loginGenerateShortsWrapper .loginGeneratorThumbnail figure {
    margin: 0;
}

.loginGenerateShortsWrapper .loginGeneratorThumbnail figure figcaption {
    color: #24282f;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif !important;
}

.loginGenerateShortsWrapper .loginGeneratorThumbnail figure figcaption span {
    color: #979797;
}

.loginGenerateShortsWrapper .loginProcessingTimeframe label,
.loginGenerateShortsWrapper .loginPreferedShortsLength label {
    color: #979797;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: "Poppins", sans-serif !important;
    margin: 0;
}

.loginGenerateShortsWrapper .loginProcessingTimeframe .shortsTimeframe {
    margin: 20px 0 20px;
    position: relative;
}

.loginGenerateShortsWrapper .loginProcessingTimeframe .shortsTimeframe .sortsTimeFrameLine {
    border-radius: 4px;
    background: var(--MainColor, #3782f7);
    height: 2px;
    width: 100%;
}

.loginGenerateShortsWrapper .loginProcessingTimeframe .timeIncreaseDecrease {
    width: 4px;
    height: 18px;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.loginGenerateShortsWrapper .loginProcessingTimeframe .shortsTimeFrameStart {
    left: 0;
}

.loginGenerateShortsWrapper .loginProcessingTimeframe .shortsTimeFrameEnd {
    right: 0;
}

.loginGenerateShortsWrapper .loginProcessingTimeframe .shortsTimeFrameTiming {
    display: flex;
    justify-content: space-between;
}

.loginGenerateShortsWrapper .loginProcessingTimeframe .shortsTimeFrameTiming p.shortsTiming {
    color: #979797;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    font-family: "Poppins", sans-serif !important;
    line-height: normal;
}

.loginGenerateShortsWrapper .loginPreferedShortsLength {
    margin-top: 40px;
}

.loginGenerateShortsWrapper .loginPreferedShortsLength .preferredShortsLength {
    display: flex;
    margin: 12px 0 0;
    padding: 0;
    grid-gap: 4px;
    align-items: center;
    flex-wrap: wrap;
}

.loginGenerateShortsWrapper .loginPreferedShortsLength .preferredShortsLength .shortLength {
    position: relative;
}

.loginGenerateShortsWrapper .loginPreferedShortsLength .preferredShortsLength .shortLength input[type="radio"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.loginGenerateShortsWrapper .loginPreferedShortsLength .preferredShortsLength .shortLength label {
    border-radius: 9px;
    padding: 9px 8px;
    background: #f0f1f2;
    color: #636363;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    border-style: none;
}

.loginGenerateShortsWrapper .loginPreferedShortsLength .preferredShortsLength .shortLength input[type="radio"]:checked+label,
.loginGenerateShortsWrapper .loginPreferedShortsLength .preferredShortsLength .shortLength input[type="radio"]:hover+label {
    background: #f0f6ff;
    color: #3782f7;
}

/* Shorts Maker Login Generate Shorts Section CSS Are Close Here */
/* AI Analyzing Your Video Success CSS Are Start Here */
.shortsAIAnalyzingSuccessWrapper {
    padding: 120px 0;
}

.shortsAIAnalyzingSuccessWrapper .shortsAiAnalyzingContainer {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.shortsAIAnalyzingSuccessWrapper .shortsAiHeading {
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    font-family: Helvetica !important;
    line-height: 60.5px;
    margin: 0;
}

.shortsAIAnalyzingSuccessWrapper .shortsAiSubHeading {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    font-family: Helvetica !important;
    margin: 8px 0 50px;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo {
    max-width: 435px;
    margin: 48px auto 0;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    padding: 0;
    border-style: none !important;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo input[type="range"]::-webkit-slider-runnable-track {
    height: 6px;
    background: #ecf3ff;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    width: 15px;
    background-color: #1679ff;
    border: 1px solid #1679ff;
    box-shadow: -407px 0 0 400px #1679ff;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo output,
.shortsAIAnalyzingSuccessWrapper .fetchingVideo .fetchingVideoContent {
    color: #979797;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: "Poppins", sans-serif !important;
    margin-top: 16px;
    padding: 0;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo .fetchingVideoContent {
    margin-top: 45px;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo .shortsAiCancel {
    border-radius: 10px;
    background: #f1f2f4;
    width: 271px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    margin: 74px auto 0;
    color: #626262;
    text-align: center;
    font-family: Helvetica !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    transition: all 1s ease;
}

.shortsAIAnalyzingSuccessWrapper .fetchingVideo .shortsAiCancel:hover {
    background: #1679ff;
    color: #fff;
}

/* AI Analyzing Your Video Success CSS Are Close Here */
/* Shorts Popup Section CSS Start Here */
.shortsMakerPopupWrapper .fade.ss_modal.modal {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 0 !important;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal::-webkit-scrollbar {
    display: none;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricing-tab-card {
    background-color: #f8f8f8;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricing-tab-card.pro {
    background-color: #f0f6ff !important;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .modal-dialog {
    max-width: 875px;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content {
    max-width: 100%;
    margin: 0;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .tab-row {
    margin: 0;
    grid-gap: 0 16px;
    flex-wrap: wrap;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .shortsPopRow {
    display: grid;
    grid-template-columns: 35% 65%;
    padding: 0 20px;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .tab-row>* {
    width: calc(50% - 16px);
    flex: none;
    margin: 0;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .nav.nav-tabs {
    margin: 0 auto 30px;
    max-width: 175px;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .nav.nav-tabs li.nav-item button {
    padding: 8px 10px;
}

.shortsMakerPopupWrapper .ModalWrapper .pricing-tab-card.free {
    display: block !important;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .nav.nav-tabs li.nav-item .save-percent {
    position: absolute;
    top: -24px;
    right: 0;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricingShortsClipWrapper img {
    max-width: 82px;
    margin-top: 60px;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricingShortsClipWrapper .shortsClipHeading {
    margin: 30px 0 56px;
    font-family: "SF Compact Display" !important;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricingShortsClipWrapper .shortsClipContent {
    color: #7d7d7d;
    font-family: "SF Compact Display" !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
    width: 80%;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .nav.nav-tabs li.nav-item .save-percent span {
    color: #fe8000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    font-family: "Poppins", sans-serif !important;
    line-height: normal;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .planLevelWrapper .pricing-list {
    max-width: 100%;
}

.shortsSeeFeature {
    color: var(--Grey7, #636363);
    text-align: center;
    font-family: "SF Compact Display" !important;
    font-family: SF Compact;
    font-size: 12px;
    font-style: normal;
    font-weight: 457;
    line-height: 134.48%;
    margin: 15px 0;
}

.shortsMakerPopupWrapper .fade.ss_modal.modal .modal-dialog .modal-content .pricing-heading h2 {
    font-weight: 500;
    font-size: 22px;
}

.shortsMakerPopupWrapper .pricing-tab-card .pricing-btn a.button {
    font-weight: 400;
}

.shortsMakerPopupWrapper .pricing-tab-card.free .pricing-btn a.button {
    background-color: #e2e3e4;
    color: #fff;
}

.shortsMakerPopupWrapper .pricingPriceContent {
    min-height: 95px;
}

/* Shorts Popup Section CSS Close Here */
/* Shorts Page Responsive CSS Are Start Here */
@media (min-width: 768px) {
    .shortsSectionWrapper .shorts-container {
        max-width: 100%;
        padding: 0 50px;
    }
}

@media (min-width: 1200px) {
    .shortsSectionWrapper .shorts-container {
        max-width: 1140px;
        padding: 0 16px;
    }
}

@media (max-width: 1199px) {

    .shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .left_section h1,
    .shortsSectionWrapper h2 {
        font-size: 40px;
        line-height: 50px;
    }

    .shortsSectionWrapper .underHeroWrapper .underHeroheading h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper {
        padding: 60px 0;
        margin-top: 60px;
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper .videoThumbContent h2 {
        font-size: 36px;
        line-height: 45px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner .StreamlineShortsColumn {
        padding: 50px 25px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .StreamlineShortsColumn .title {
        font-size: 24px;
        line-height: 32px;
    }

    .shortsSectionWrapper .aiGenerateShortsWrapper {
        margin-top: 60px;
        padding: 60px 0;
    }

    .shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .planTitle {
        font-size: 20px;
        line-height: 1;
    }

    .shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn .price {
        font-size: 26px;
    }

    .shortsSectionWrapper .faqWrapper,
    .shortsSectionWrapper .freeSapmleClipsWrapper {
        margin-top: 60px;
    }

    .shortsSectionWrapper .freeSapmleClipsWrapper .freeSampleClipsInner {
        padding: 60px 0;
    }

    .successSectionWrapper .successSectionHeading h2 {
        font-size: 36px;
        line-height: 50px;
    }

    .successSectionWrapper .successSectionSubHead h4 {
        font-size: 22px;
        line-height: 26px;
    }
}

@media (max-width: 991px) {
    .shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper {
        grid-gap: 30px;
    }

    .shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .left_section h1,
    .shortsSectionWrapper h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .shortsSectionWrapper .formShortcode .formInputFields .submitField {
        max-width: 135px;
    }

    .shortsSectionWrapper .formShortcode .formInputFields .submitField input[type="submit"] {
        font-size: 14px;
    }

    .shortsSectionWrapper .underHeroWrapper .underHeroheading h2 {
        font-size: 28px;
        line-height: 32px;
    }

    .shortsSectionWrapper .underHeroWrapper .underHeroImgGroup {
        grid-gap: 20px;
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper .videoThumbContent h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper .videoThumbContent p {
        font-size: 16px;
        line-height: 26px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner {
        grid-gap: 0 20px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .StreamlineShortsColumn .title {
        font-size: 20px;
        line-height: 28px;
        margin: 0 0 15px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner .StreamlineShortsColumn {
        padding: 30px 15px;
    }

    .shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn {
        padding: 30px;
    }

    .shortsSectionWrapper .freeSapmleClipsWrapper .formShortcode {
        max-width: 500px;
    }

    .successSectionWrapper .successSectionHeading h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .successSectionWrapper .successSectionSubHead h4 {
        font-size: 18px;
    }

    .successSectionWrapper .successSectionContent {
        padding: 30px;
    }

    .shortsSectionWrapper .underHeroWrapper .underHeroImgGroup .underHeroImage {
        width: calc(33.3% - 20px);
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .shortsPopRow {
        grid-template-columns: 1fr;
        grid-gap: 40px 0;
        padding: 0;
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricingShortsClipWrapper img {
        margin-top: 0;
    }

    .shortsMakerPopupWrapper .pricingShortsClipWrapper {
        text-align: center;
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricingShortsClipWrapper .shortsClipHeading {
        margin: 10px 0 20px;
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content {
        max-width: 100%;
        margin: 0 !important;
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .modal-dialog .modal-content {
        padding: 30px 10px 20px;
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .pricingShortsClipWrapper .shortsClipContent {
        margin: 0 auto;
    }
}

@media (max-width: 767px) {
    .planLevelWrapper {
        border-radius: 30px;
        padding: 30px;
    }

    .planLevelWrapper h3 {
        font-size: 26px;
    }

    .shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .left_section {
        flex: none;
    }

    .shortsSectionWrapper .HeroSectionWrapper .shortsHeroInnerWrapper .right_section {
        width: 100%;
    }

    .shortsSectionWrapper .underHeroWrapper .underHeroImgGroup .underHeroImage {
        width: calc(50% - 20px);
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper {
        grid-gap: 30px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner {
        grid-gap: 20px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner .StreamlineShortsColumn {
        flex: none;
        width: calc(50% - 20px);
    }

    .shortsSectionWrapper *:not(.aiGenerateShortsRow *) {
        width: 100%;
    }

    .loginGenerateShortsWrapper .loginGeneratorShortsContainer {
        max-width: 480px;
        margin: 0 auto;
    }

    .shortsAIAnalyzingSuccessWrapper .shortsAiAnalyzingContainer {
        max-width: 480px;
    }

    .shortsAIAnalyzingSuccessWrapper .shortsAiHeading {
        font-size: 30px;
        line-height: 36px;
    }
}

@media (max-width: 575px) {
    .loginGenerateShortsWrapper .loginGeneratorShortsContentBox {
        padding: 20px;
    }

    .shortsSectionWrapper .underHeroWrapper .underHeroImgGroup .underHeroImage {
        width: 100%;
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper .vidoesThumbWrapper>* {
        flex: none;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner .StreamlineShortsColumn {
        width: 100%;
    }

    .shortsSectionWrapper .aiGenerateShortsWrapper .aiGenerateShortsRow {
        flex-wrap: wrap;
        grid-gap: 60px;
    }

    .shortsSectionWrapper .aiGenerateShortsWrapper .aiGeneratesColumn {
        flex: none;
        width: 100%;
    }

    .shortsSectionWrapper .formShortcode .formInputFields .inputField {
        flex: none;
    }

    .page-template-template-shorts header#masthead div#site-logo img {
        max-width: 200px;
    }

    .page-template-template-shorts header#masthead {
        align-items: center;
    }

    .shortsSectionWrapper .freeSapmleClipsWrapper .freeSampleClipsInner {
        padding: 30px;
    }

    .shortsSectionWrapper .formShortcode .formInputFields .inputField input {
        padding: 0 140px 0 15px;
    }

    .shortsSectionWrapper .faqWrapper .feature-faq h4 {
        margin: 0;
    }

    .loginGenerateShortsWrapper .loginGeneratorShortsContainer {
        max-width: 100%;
        padding: 0 16px;
    }

    .loginGenerateShortsWrapper .loginGeneratorShortsContentBox h6.generatorShorts {
        font-size: 14px;
        text-align: center;
    }

    .loginGenerateShortsWrapper .loginGeneratorShortsContentBox .shortsMakersTalkingVideos .worksBestTalkingVideos li {
        font-size: 14px;
    }

    .loginGenerateShortsWrapper .loginGeneratorShortsContentBox .shortsMakersTalkingVideos .worksBestTalkingVideos {
        flex: none;
        width: 100%;
    }

    .loginGenerateShortsWrapper .loginGeneratorShortsContentBox .shortsMakersTalkingVideos {
        grid-gap: 10px;
        margin-top: 10px;
    }

    .shortsAIAnalyzingSuccessWrapper .shortsAiAnalyzingContainer {
        max-width: 100%;
        padding: 0 16px;
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .tab-row>* {
        width: 100%;
    }

    .shortsMakerPopupWrapper .fade.ss_modal.modal .pricing-tab-content .tab-row {
        grid-gap: 16px 0;
    }

    .shortsMakerPopupWrapper .pricingPriceContent {
        min-height: 100%;
    }
}

@media (max-width: 420px) {
    .shortsSectionWrapper .formShortcode .formInputFields .submitField {
        position: static;
        max-width: 100%;
        transform: translate(0);
        margin-top: 20px;
    }

    .shortsSectionWrapper .formShortcode .formInputFields .inputField input {
        padding: 0 15px;
    }

    .shortsSectionWrapper .formShortcode .formInputFields label#youtubeChannel-error {
        position: static;
    }

    .successSectionWrapper .success-headings_wrapper {
        grid-gap: 20px 0;
        flex-wrap: wrap;
    }
}

/* Shorts Page Responsive CSS Are Close Here */
/* Shorts Page CSS Are Close Here */

/* New css */
.draftSection span {
    font-size: 10px;
}

.logo-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.logo-container>img {
    width: 17%;
    flex: 0 0 auto;
    height: 100px;
    object-fit: contain;
}

.partner_logos_warpper {
    margin-top: 40px;
}

.grey_bg {
    background-color: #f2f5f9;
}

.shortsSectionWrapper>section {
    padding: 80px 0;
}

.shortsSectionWrapper .heroSection {
    padding-top: 15px;
}

.multipleVideosThumbInner {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 30px 20px;
    margin-top: 40px;
}

.multipleVideosThumbInner>div {
    flex: 0 0 auto;
    width: calc(50% - 14px);
}

.videosThumbImage {
    background-color: #000;
    border-radius: 20px;
    overflow: hidden;
    aspect-ratio: 16/10.5;
    padding: 5px;
}

.videosThumbImage img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.videoThumbContent h3 {
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 6px;
    color: #1b1a23;
    letter-spacing: 0px !important;
}

.pefectToolsSection .FeedbackSectionWrapper {
    text-align: center;
}

.pefectToolsSection {
    text-align: center;
}

.pefectToolsSection .PerfectToolsHeading p {
    max-width: 824px;
    display: inline-block;
    margin-bottom: 0;
}

.FeedbackContentRepeat {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
}

.FeedbackContentRepeat>div {
    flex: 0 0 auto;
    width: calc(50% - 20px);
    text-align: left;
}

.FeedbackContentRepeat h3 {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
}

.FeedbackContentRepeat p {
    font-size: 18.6px;
    line-height: 1.6;
}

.pricing-sec {
    background-color: #ecf2fe;
    margin-top: 80px;
    padding: 80px 50px;
    border-radius: 20px;
}

.pricing-sec h2 {
    text-align: center;
}

.pricing-sec .pricing-tab-content .tab-row .pricing-tab-card {
    background-color: #fff !important;
}

.pricing-sec .pricing-tab-content.ssembleYtDownloaderPrice {
    margin-bottom: 0 !important;
}

.shortsSectionWrapper .freeSapmleClipsWrapper {
    margin-top: 0;
}

.shortsSectionWrapper .freeSapmleClipsWrapper .freeSampleClipsInner {
    padding: 0;
}

.gradiant_bg {
    background: linear-gradient(0, #f2f5f9 0.06%, rgba(242, 245, 249, 0) 88.95%);
}

.aishorts-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.aishorts-row:nth-child(2n + 1) {
    flex-direction: row-reverse;
}

.aishorts-row>div {
    padding: 0 15px;
    text-align: left;
    width: 50%;
    flex: 0 0 auto;
}

.aishorts-row .aishorts-content h3 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 24px;
    letter-spacing: 0;
}

.aishorts-row .aishorts-img img {
    display: inline-block;
    max-width: 450px;
    height: auto;
}

.aishorts-row:nth-child(2) {
    margin-bottom: 60px;
}

.aishorts-sec h2 {
    margin-bottom: 100px;
}

.aishorts-sec {
    padding: 110px 0 0;
}

.aishorts-row .aishorts-img img {
    max-width: 440px;
    width: 100%;
}

.shortsSectionWrapper .aishorts-content p {
    margin-bottom: 0;
}

.TutorialSectionWrapper {
    text-align: center;
}

.TutorialSectionWrapper .tutorialIframe iframe {
    width: 100%;
    max-width: 962px;
    aspect-ratio: 16/9;
    height: auto;
    border-radius: 12px;
    margin-top: 50px;
}

.aishorts-row:nth-child(2n + 1)>div.aishorts-img {
    text-align: end;
}

.FeedbackSectionWrapper .FeedbackHeading {
    margin-bottom: -70px;
    margin-top: 45px;
}

.feedback-sec {
    overflow: hidden;
}

.feedback-sec .FeedbackThumbInner {
    margin-left: -9%;
    margin-right: -9%;
    width: auto;
}

.gradiant_bg {
    padding-top: 94px;
    padding-bottom: 94px;
}

.PerfectToolsHeading p {
    font-size: 16px;
}

.pricing-tab-content .nav-tabs {
    border-radius: 24px;
    background-color: #fafafa;
}

.pricing-tab-content .nav-tabs .nav-item button {
    font-weight: 400;
}

.pricing-tab-content .nav-tabs .nav-item button.tab.active {
    font-weight: 500;
    border: 1px solid #ecf2fe;
    box-shadow: -1px 3px 6px rgb(236, 242, 254);
    border-radius: 27px;
    padding: 12px 63px;
}

.shortsSectionWrapper .formShortcode {
    padding-right: 31px;
}

@media (max-width: 991px) {
    .TutorialSectionWrapper .tutorialIframe iframe {
        margin-top: 30px;
    }

    .videoThumbContent h3 {
        font-size: 20px;
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper {
        padding-bottom: 0;
    }

    .FeedbackContentRepeat h3 {
        font-size: 20px;
    }

    .FeedbackContentRepeat p {
        font-size: 16px;
        margin-top: 12px;
    }

    .aishorts-sec {
        padding: 70px 0 0;
    }

    .aishorts-sec h2 {
        margin-bottom: 60px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper {
        padding-top: 50px;
    }

    .aishorts-row .aishorts-content h3 {
        margin-bottom: 10px;
        font-size: 24px;
    }

    .shortsSectionWrapper p {
        margin-top: 15px;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .StreamlineShortsColumn .description {
        font-size: 16px;
    }

    .pricing-sec {
        padding: 36px 34px;
    }

    .shortsSectionWrapper>section {
        padding: 60px 0;
    }

    .shortsSectionWrapper .straemlineShortsMakingWrapper .shtreamlineShortsInner .StreamlineShortsColumn .description {
        margin-bottom: 0;
    }

    .gradiant_bg {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .FeedbackSectionWrapper .FeedbackHeading {
        margin-bottom: -48px;
        margin-top: 31px;
    }
}

@media (max-width: 767px) {
    .FeedbackSectionWrapper .FeedbackHeading {
        margin-bottom: -20px;
        margin-top: 30px;
    }

    .logo-container>img {
        width: 33% !important;
    }

    .shortsSectionWrapper>section {
        padding: 50px 0;
    }

    .shortsSectionWrapper h2 {
        font-size: 28px;
    }

    .multipleVideosThumbInner {
        margin-top: 20px;
    }

    .shortsSectionWrapper .multipleVideosThumbnailWrapper {
        padding-top: 0;
    }

    .FeedbackContentRepeat p {
        margin-bottom: 0;
    }

    .aishorts-sec {
        padding: 50px 0 0;
    }

    .aishorts-sec h2 {
        margin-bottom: 20px;
    }

    .aishorts-content {
        margin-top: 21px;
    }

    .aishorts-row .aishorts-content h3 {
        margin-bottom: 0;
    }

    .aishorts-row .aishorts-content h3+p {
        margin-top: 7px;
    }

    .aishorts-row:not(:last-child),
    .aishorts-row:nth-child(2) {
        margin-bottom: 41px;
    }

    .pricing-sec {
        margin-top: 20px;
    }
}




/* new UI css */
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body.dcs-iframe-only {
    line-height: 1.5;
    font-weight: 400;
    padding: 10px 0 60px;
    /* margin: -16px 0px; */
    height: unset;
}

.dcs-iframe-only .social-uploader>h3 {
    text-align: center;
    font-size: 16px;
    margin-bottom: 0px;
    padding: 18px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    margin-top: 0;
    z-index: 9;
    font-weight: 600;
}

.dcs-iframe-only .socialUploader {
    padding: 20px 20px 20px 20px;
    border-top: 1px solid #E2E3E4;
}

.dcs-iframe-only .ConnectionWrapper {
    display: flex;
    align-items: center;
}

.dcs-iframe-only .checkboxWrapper>input {
    margin: 0;
    width: 24px;
    height: 24px;
    line-height: 1;
    display: inline-block;
    position: relative;
    top: 0.5px;
    accent-color: #3782f7;
    border-radius: 4px;
    cursor: pointer;
    appearance: none;
    background-repeat: no-repeat;
    border: 1px solid #3782f7;
    background-size: cover;
}

img.socialIconsSmalll {
    margin-right: 5px;
}

h2.accordion-header {
    border: 1px solid #000;
}

h2.accordion-header button.accordion-button {
    font-weight: 600;
}

.accordion-item {
    margin-bottom: 18px;
    border: 1px solid #ccc;
}


.form-check-input[type=checkbox] {
    border: 1px solid #3782f7;
}

.form-check-input:disabled {
    background-color: #ccc;
}


.dcs-iframe-only .checkboxWrapper>input:checked {
    background-image: url('./assets/checkbox.svg');
    border-color: transparent;
}

.dcs-iframe-only .checkboxWrapper>input[disabled] {
    opacity: 0.2;
    border: 1px solid;
    background: #ccc;
    cursor: not-allowed;
}

.dcs-iframe-only .linkWrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 2px;
    line-height: 1;
    font-size: 14px;
    font-family: "Noto Sans";
    background: transparent;
    border: none;
    cursor: pointer;
    font-weight: 500;
    color: #24282F;

}

.dcs-iframe-only .linkWrapper>img {
    width: 24px;
    aspect-ratio: 1/1;
    object-fit: contain;
    object-position: center;
    margin-bottom: 1px;
    flex: 0 0 auto;
}



.dcs-iframe-only .ConnectionWrapper p {
    margin: 0;
}

.dcs-iframe-only .checkboxWrapper {
    line-height: 2;
    display: inline-block;
    margin-right: 10px;
    padding-left: 0px;
}

.dcs-iframe-only .logoWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "Noto Sans";
    font-weight: 400;
    font-size: 16px;
    color: #24282F;
}


.dcs-iframe-only .logoWrapper>img {
    width: 24px;
    aspect-ratio: 1/1;
    object-fit: contain;
    object-position: center;
}


.dcs-iframe-only .inputWrapper {
    border: 1px solid #b1b7bd;
    padding: 16px;
    border-radius: 20px;
}

.dcs-iframe-only .checkboxWrapper label {
    padding-left: 10px !important;
}

.dcs-iframe-only .inputWrapper:not(:last-child) {
    margin-bottom: 8px;
}

.dcs-iframe-only .inputWrapper .label {
    display: flex;
    font-size: 12px;
    font-weight: 500;
    justify-content: space-between;
    margin-bottom: 4px;
    font-family: "Noto Sans";
    color: #999999;
}

.dcs-iframe-only .inputWrapper .label p {
    margin: 0;
    font-weight: 400;
}

.dcs-iframe-only .inputWrapper .text-control {
    width: 100%;
    max-width: unset;
    min-width: unset;
    display: block;
    box-sizing: border-box;
    outline: none;
    color: #24282F;
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border: none;
    padding: 8px 12px !important;
    line-height: normal;
    margin-top: 5px !important;
    border-radius: 4px;
    border: 1px solid #5789d6;
}


.dcs-iframe-only .formWrapper {
    margin-top: 18px;
}

.dcs-iframe-only .tagsWrapper {
    background-color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
}

.dcs-iframe-only .inputWrapper .tagsWrapper .text-control {
    padding: 0 !important;
    margin-top: 0 !important;
}

.dcs-iframe-only .tagsWrapper>div {
    display: flex;
    width: 100%;
    gap: 6px;
    flex-wrap: wrap;
}

.dcs-iframe-only .tagsWrapper>div .tagsList {
    background-color: #E0E0E0;
    font-size: 14px;
    color: #000;
    font-family: "Noto Sans";
    border-radius: 3px;
    padding: 2px 6px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
}

.dcs-iframe-only .tagsWrapper>div .tagsList>button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    width: 10px;
    flex: 0 0 auto;
}

.dcs-iframe-only .tagsWrapper>div .tagsList>button:hover {
    opacity: 0.7;
}

.dcs-iframe-only .tagsWrapper>div .tagsList>button>img {
    object-fit: contain;
    object-position: center;
    /* margin-top: 6px; */
    aspect-ratio: 1/1;
    width: 100%;
}


.dcs-iframe-only .select-wrapper .accessSpecifiers {
    background-color: #F0F1F2;
    padding: 12px 16px;
    width: 100%;
    border-radius: 6px;
    border: none;
    outline: none;
    appearance: none;
    background-image: url('./assets/Arrow_opened.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) center;
    background-size: 10px;
    color: #757575;
    font-weight: 500;
    font-family: "Noto Sans";
    font-size: 14px;
}


.dcs-iframe-only .fixed-submit-btn {
    background-color: #fff;
    padding: 12px 19px;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
}


.dcs-iframe-only .fixed-submit-btn .submit-btn-btn {
    background-color: #3782F7;
    border: none;
    color: #fff;
    padding: 12px 19px;
    display: block;
    width: 100%;
    border-radius: 11px;
}


.dcs-iframe-only .fixed-submit-btn {
    background-color: #fff;
    padding: 12px 19px;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
}



.dcs-iframe-only .fixed-submit-btn .submit-btn-btn {
    background-color: #3782F7;
    border: none;
    color: #fff;
    padding: 11px 19px;
    display: block;
    width: 100%;
    border-radius: 11px;
    cursor: pointer;
    font-size: 16px;
}

.dcs-iframe-only .fixed-submit-btn .submit-btn-btn[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
}


.dcs-iframe-only .socialUploader:first-child {
    padding-top: 0;
    border-top: unset;
}


.dcs-iframe-only .spinningLoader:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #e1ecfd;
    border-radius: 50%;
    border-top-color: #3782F7;
    animation: rotation 1s linear infinite;
}

.dcs-iframe-only .spinningLoader.ss_primary:before {
    border-color: #b5d2ff42;
    border-top-color: #fff;
}

.dcs-iframe-only .fixed-submit-btn .submit-btn-btn.spinningLoader.ss_primary[disabled] {
    opacity: 1;
}


.dcs-iframe-only .fixed-submit-btn .submit-btn-btn.spinningLoader.ss_primary:before {
    position: relative;
    top: 3px;
}

.dcs-iframe-only .inputWrapper textarea.text-control {
    resize: none;
    height: 100px;
}

.dcs-iframe-only .linkWrapper.disconnect-color {
    color: #7D7D7D;
}

.validation-sorts {
    background-color: #FEF2E9;
    Padding: 10px 16px 10px 16px;
    display: flex;
    gap: 4px;
    color: #636363;
    font-size: 12px;
    font-family: 'Noto Sans';
    font-weight: 400;
    margin-bottom: 8px;
    border-radius: 6px;
}

.validation-sorts:before {
    content: "";
    background-image: url("./assets/Warningwarning-icon.svg");
    height: 16px;
    width: 14px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    flex: 0 0 auto;
}

.linkWrapper.view-link-section {
    display: flex;
    gap: 14px;
}

.view-link-section a {
    display: flex;
    align-items: center;
    gap: 3px;
    font-family: "Noto Sans";
    font-size: 14px;
}

.view-link-section>a>img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    object-position: center;
}

.logoWrapper>p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.checkboxInlineWrapper {
    display: flex !important;
    width: 100%;
    gap: 32px;
}


._loading_overlay_overlay.css-lzr3bi-LoadingOverlayWrapperBase {
    z-index: 99999;
}


.videoPreviewWrap video {
    max-height: 380px;
}



.shortsPreviewWrapper {
    /* text-align: center; */
    max-width: 700px;
    margin: 0 auto;
    padding: 20px 0px;
}

.leftrightWap {
    display: flex;
    gap: 15px;
}

.leftrightWap .right {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 15px;
}

.clipInfoWrap p {
    font-size: 17px;
    padding: 0;
    margin: 0;
}

.videoPreviewWrap button {
    width: 100%;
    border-radius: 0;
}

.previewWithInfoWrapper h2 {
    font-size: 25px;
    text-align: left;
    line-height: 1.2;
    margin: 30px 0px;
}

.clipInfoWrap {
    display: flex;
    align-items: baseline;
    gap: 10px;
}

.videoPreviewWrap video {
    border-radius: 20px;
}

span.virality_score {
    color: #00c5f0;
    font-size: 29px;
    font-weight: bold;
    line-height: 1;
}

span.staticText {
    font-weight: bold;
}

.ss-btnWrapper {
    text-align: center;
    border-top: 2px solid #ccc;
    padding-top: 20px;
    margin-top: 20px;
}


.clipInfoWrap {
    display: flex;
    align-items: baseline;
    gap: 10px;
}

.clipInfoWrap .right {
    display: inline-grid;
    text-align: center;
}



/* new css for shorts api implementation */

.app-root {
    margin-inline: 16px;
}


img.card-img-top {
    opacity: 0.5 !important;
}

.card.rounded-5.bg-light.border-0.task_complete img.card-img-top {
    opacity: 1 !important;
}

.alert.alert-danger.centered.mt-4 p {
    font-size: 15px;
}

/* .card.rounded-5.bg-light.border-0.failed {
    pointer-events: none;
} */

.togglePalletAction {
    pointer-events: auto;
}

.link-input {
    background-image: url('./assets/link.svg');
    background-position: 12px center;
    background-repeat: no-repeat;
    padding: 15px 15px 15px 35px;
    outline: 0;
    border: 2px solid transparent;
}

.link-input:focus-visible {
    border: 2px solid #3782F7;
}

.link-input.has-error {
    color: #e91a3c;
    border: 2px solid #e70026e5;
}

.link-input.has-error:focus-visible {
    border: 2px solid #e70026e5;
}

.yt-video-thumbnail {
    max-width: 100%;
    margin: 0 auto;
}

.get-shorts {
    border-radius: 15px;
    background-color: #3782F7;
}

.get-shorts[disabled] {
    opacity: 0.6;
}

.tool-features ul li {
    font-size: 13px;
}

.short-item {
    cursor: pointer;
}

.short {
    display: flex;
    border-radius: 7px;
    padding: 9px;
    column-gap: 6px;
    margin-bottom: 10px;
}

.short-left {
    width: 145px;
    height: 258px;
    position: relative;
}

.short-left .cover {
    border-radius: 7px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.short-right {
    flex: 1;
}

.separator {
    border: 0;
    height: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.popular {
    position: absolute;
    top: 6px;
    left: 6px;
    font-size: 13px;
    font-weight: 700;
}

.viral-score {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 42px;
    height: 42px;
}

.horizontal-slider {
    width: 90%;
    max-width: 500px;
    margin: auto;
}

.horizontal-slider .slider-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.20);
    border-radius: 2px;
    display: block;
    height: 18px;
    width: 4px;
    top: 12px;
}

.horizontal-slider .slider-thumb-1 {
    left: 100%;
}

.horizontal-slider .slider-thumb.active {
    background-color: grey;
}

.slider-track {
    position: relative;
    background: #3782F7;
}

.slider-track.slider-track-0 {
    background: #F0F1F2;
}

.slider-track.slider-track-2 {
    background: #F0F1F2;
}

.horizontal-slider .slider-track {
    top: 20px;
    height: 4px;
}

.horizontal-slider .time-marker {
    position: absolute;
    top: 36px;
    font-size: 12px;
}

.slider-thumb.slider-thumb-1+.time-marker {
    left: unset;
    right: 0;
}


.user-video .video-thumbnail {
    height: 191px;
}

.user-video .bg-overlay {
    background-color: rgba(255, 255, 255, 0.3);
}

.actions-popover {
    padding: 10px 20px;
    border-radius: 13px;
    background: #FFF;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
}

.actions-popover .delete-btn {
    padding-left: 32px;
    /* background-image: url('/trash.svg'); */
    background-repeat: no-repeat;
    background-position: 0px center;
}

button.ss_btn_outlined {
    font-size: 15px;
    line-height: 1.75rem;
    color: #9d9d9d;
    border-color: rgb(153 153 153 / var(--tw-border-opacity));
    border-width: 2px;
    border-radius: 1rem;
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    background-color: transparent;
    background-image: none;
    min-width: 210px;
    padding: 12px;
}

/** Styles for shorts list component */

.SingleShortWrapper video {
    max-width: 100%;
}

/* .loginGeneratorShortsInner .formShortcode {
    max-width: 436px;
    margin: 0 auto;
} */

.loginGeneratorShortsInner .shortsMakerSmallWrap {
    max-width: 436px;
    margin: 0 auto;
}


.short-item .SingleShortWrapper>.short {
    padding: 7px;
    background-color: rgba(226, 227, 228, 1);
    gap: 8px;
    margin-bottom: 6px;
}

.SingleShortWrapper .short-right h3 {
    letter-spacing: 0;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid rgba(187, 188, 189, 1);
    padding-bottom: 10px;
    margin-bottom: 10px;
    line-height: 1.1;
    margin-top: 2px;
}

.SingleShortWrapper * {
    font-family: 'Noto Sans' !important;
}

.SingleShortWrapper .short-right p {
    font-size: 14px;
    line-height: 1.2;
    color: rgba(36, 40, 47, 1);
}


.SingleShortWrapper .short-left {
    border-radius: 8px;
    overflow: hidden;
}

.SingleShortWrapper .short-left :is(.popular, .viral-score) {
    background-color: rgba(86, 0, 192, 1);
    color: #fff;
    left: unset;
    right: 6px;
    text-align: center;
    text-transform: uppercase;
    padding: 5px 4px !important;
    border-radius: 5px !important;
}

.SingleShortWrapper .short-left .popular {
    width: calc(100% - 12px);
    transform: translateX(-50%);
    left: 50%;
    font-size: 13px;
    color: rgba(255, 230, 0, 1);
    font-weight: 500;
}

.SingleShortWrapper .short-left .videoWrap {
    width: 100%;
}

.SingleShortWrapper .short-left .videoWrap video {
    width: 100%;
}

.SingleShortWrapper .short-left .popular>img {
    width: 14px;
    height: 14px;
}

.SingleShortWrapper .short-left .viral-score .text-yellow {
    font-size: 20px;
    font-weight: 600;
    color: rgba(255, 230, 0, 1);
    line-height: 1;
}

.SingleShortWrapper .short-left .viral-score .score-label {
    font-size: 10px;
    font-weight: 100;
    line-height: 1;
    margin-top: 3px;
}

.short-item {
    cursor: unset;
}

.short-item:not(:last-child) {
    margin-bottom: 20px;
}

.short-item:not(:last-child) {
    margin-bottom: 20px;
}

.button-group {
    display: flex;
    justify-content: normal;
    gap: 5px;
}


.shortClipTime {
    position: absolute;
    color: #fff;
    left: 12px;
    top: 5px;
    font-size: 12px;
}


.button-group .reels-preview-btn {
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    font-size: 14px;
    padding: 10px 10px;
    min-width: 113px;
    border: none;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 50%;
}


button.ss_back_btn {
    background: transparent;
    border: none;
    display: flex;
    gap: 6px;
    padding: 15px 0px;
}

button.ss_back_btn span {
    font-size: 13px;
    line-height: 1rem;
}

.button-group .download-btn {
    background-color: rgba(55, 130, 247, 1);
    color: #fff;
    margin-right: 6px;
    width: 100%;
}

.button-group a {
    width: 50%;
}

.button-group a[disabled] {
    opacity: 0.5;
}

.button-group .edit-btn {
    background-color: rgba(240, 246, 255, 1);
    color: rgba(55, 130, 247, 1);
}

a.mainTitleWrap {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-family: 'Noto Sans' !important;
    color: #000 !important;
    justify-content: center;
    margin-bottom: 25px;
}

h3.charpterHeading {
    font-family: 'Noto Sans' !important;
}

p.timingWraper {
    display: flex;
    margin: 0;
    font-family: 'Noto Sans' !important;
    font-size: 20px;
    margin-left: 10px;
    font-weight: 600;
    color: #7d7d7d;
}

a.mainTitleWrap h3 {
    font-weight: bold;
    margin: 0;
    padding: 0;
    font-size: 20px;
    margin-left: 4px;
    font-family: 'Noto Sans' !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0;
}

button.ss_btn_back {
    border: none;
    border-radius: 10px;
    color: #626262;
    background-color: #F1F2F4;
    padding: 10px 30px;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Helvetica' !important;
    min-width: 271px;
}

p.timingWraper * {
    font-family: 'Noto Sans' !important;
}

.loginGenerateShortsWrapper .loginGeneratorShortsContainer {
    max-width: 100% !important;
    margin: 0 auto;
}



.loginProcessingTimeframe>div {
    align-items: center;
    gap: 12px;
}

.loginProcessingTimeframe .form-select {
    padding: 6px 10px;
    border-radius: 7px;
    border: none;
    background-color: #f1f2f4;
    font-weight: 600;
    color: #585858;
    font-size: 13px;
    transition: 0.3s;
    outline: 1px solid transparent;
}

.loginProcessingTimeframe .form-select:focus {
    outline: 1px solid #3782f7;
    box-shadow: none;
}

.ss_back_btn>span {
    font-weight: 600;
}

button.ss_back_btn {
    display: flex !important;
    align-items: center;
    gap: 6px;
    color: #5a5a5a;
}

button.ss_back_btn>img {
    width: 6px;
}

.shortsImageWrapper {
    position: relative;
    z-index: 0;
}

.shortsImageWrapper:before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: #ffffffe3;
    z-index: 1;
}

.shortsImageWrapper:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('./assets/WarningCircle.svg');
}

.shortsImageWrapper:not(.error):before,
.shortsImageWrapper:not(.error):after {
    display: none;
}


.error-response .error-msg {
    font-size: 21px;
    text-align: center;
    color: #F80000;
    line-height: 1.4;
    margin-bottom: 22px;
}

.error-response .error-display {
    text-align: left;
    background-color: #F0F1F2;
    min-height: 75px;
    font-size: 14px;
    border-radius: 5px;
    color: #7D7D7D;
    font-family: 'Noto Sans' !important;
    padding: 7px 13px;
    margin-bottom: 40px;
}

.shortsListTopNavWrap {
    text-align: center;
}

.shortsListTopNav {
    display: inline-block;
}

@media (max-width:991px) {
    a.mainTitleWrap {
        flex-wrap: wrap;
        grid-gap: 10px 0;
        padding: 0 15px;
    }

    a.mainTitleWrap h3 {
        white-space: pre-wrap !important;
    }

    button.ss_back_btn {
        justify-content: center;
        margin: 0 auto;
    }

    p.timingWraper {
        font-size: 16px;
    }
}

/* end of css for shorts api */

/* additional css */
.youtube-down-con.shortsMakerProgressWrapper {
    max-width: 522px;
    margin: 0 auto;
    margin-top: 60px;
}

.tools-sec {
    margin: 60px auto 0;
    max-width: 522px;
    text-align: center;
}

.alert.alert-danger p {
    padding: 0;
    margin: 0;
}

.shortsCheckedMark {
    background-image: url('./assets/tick.png');
}

.shortsCroseMark {
    background-image: url('./assets/cross.png');
}

ul.worksBestTalkingVideos {
    list-style: none;
}



.CheckingStatus form {
    display: none;
}

.CheckingStatus::after {
    content: '';
    position: absolute;
    top: calc(50% + 200px);
    left: 50%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, calc(-50% - 100px)) rotate(0);
    border: 5px solid #000;
    border-right-color: transparent;
    border-radius: 100%;
    animation: loaderRotate 1.5s infinite linear;
}

@keyframes loaderRotate {
    0% {
        transform: translate(-50%, calc(-50% - 100px)) rotate(0);
    }

    100% {
        transform: translate(-50%, calc(-50% - 100px)) rotate(360deg);
    }
}

.CheckingStatus {
    height: 100%;
    position: relative;
}

.msg-tool {
    display: flex;
    align-items: baseline;
    vertical-align: top;
    gap: 10px;
}

/* Only for drag and drop on shortscreator */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.2/font/bootstrap-icons.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/antd/4.21.7/antd.min.css");

/* shorts maker and narrator css */

.ytDownloaderBody div#primary,
.page-id-4164 div#primary {
    padding: 0;
}

.errorWrapperForCats p {
    padding: 0;
    margin: 0;
}


.shorts-inner-container {
    max-width: 750px;
    margin: 0 auto;
    text-align: center;
}

.light_blue_bg {
    background: linear-gradient(0deg, #CBD6EA 0%, #ECF3FF 100%);
}

.yellow_bg {
    background: linear-gradient(180deg, #FFF3E2 0%, #FFE8C7 100%);
}

.shorts-container.shortsSectionWrapper {
    padding: 100px;
}

section.ShortsMakerModuleSection.light_blue_bg {
    margin-top: 40px;
}

.serveyFormWrapper {
    padding-bottom: 60px;
}

.shortsFormWrappr form {
    text-align: left;
}

.shortsModuleFormWrapper .shortsSectionWrapper {
    padding-top: 55px;
}

.shortsModuleLinkWrapper a {
    color: var(#1679FF);

    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
}

.shortsModuleLinkWrapper {
    padding-top: 27px;
}

.shortsModuleFormWrapper {
    margin-bottom: 25px;
}


.ShortsModuleFramesWrapper.underHeroWrapper .embed-container div>iframe {
    aspect-ratio: 9/16;
    height: auto;
    width: 100%;
    background-color: #000;
    border-radius: 20px;
}

/* end of shorts maker and narrator css */

h4.onlyForPopup {
    display: none;
}

.ss_modal.modal h4.onlyForPopup {
    display: block;
    font-size: 18px;
    margin-top: 10px;
    font-weight: bold;
    color: #3782F7;
}

.ss_modal.modal .pricing-heading {
    display: inline-block;
}

.ModalWrapper .pricing-tab-card.free {
    display: none !important;
}

.fade.ss_modal.modal.show {
    opacity: 1;
    z-index: 9999;
}

.fade.ss_modal.modal .modal-dialog {
    max-width: 850px;
    height: 100vh;
    border-radius: 1rem;
    box-shadow: none;
}

.fade.ss_modal.modal .modal-dialog .modal-content {
    padding: 2.5rem;
    max-height: calc(100% - 40px);
    overflow: auto;
    border-radius: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fade.ss_modal.modal .modal-dialog .modal-content::-webkit-scrollbar {
    display: none;
}

.fade.ss_modal.modal .modal-dialog .modal-content .planLevelWrapper {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
}

.fade.ss_modal.modal .modal-dialog .modal-content .planLevelWrapper .pricing-tab-card.pro {
    background-color: #F8F8F8;
}

.fade.ss_modal.modal .modal-dialog .pricing-tab-content.ssembleYtDownloaderPrice {
    margin-top: 40px;
}

.fade.ss_modal.modal .modal-dialog .modal-content .modal-header {
    border-style: none;
    position: absolute;
    top: 12px;
    right: 12px;
}

.fade.ss_modal.modal .modal-dialog .modal-content .modal-header button.btn-close {
    font-size: 16px;
    outline: none !important;
    padding: 0;
    box-shadow: none;
}

/* code for magic video */
.otherButtonsMainWrapper {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    padding: 80px 0;
}

.c_section_title {
    font-size: 35px;
    font-weight: 700;
    text-transform: capitalize;
    width: 60%;
    margin: 0 auto;
}

.otherButtonsMainWrapper .otherButtonsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 36px 34px;
    margin-top: 40px;
}

.otherButtonsMainWrapper .otherButtonsWrapper .otherButtonsInnerWrapper {
    width: calc(33.3% - 34px);
    border-radius: 10px;
    overflow: hidden;
}

@media (max-width:767px) {
    .otherButtonsMainWrapper .otherButtonsWrapper {
        grid-gap: 25px;
        margin-top: 25px;
    }

    .otherButtonsMainWrapper .otherButtonsWrapper .otherButtonsInnerWrapper {
        width: calc(50% - 25px);
    }

    .c_section_title {
        width: 80%;
    }
}

@media (max-width:480px) {
    .otherButtonsMainWrapper .otherButtonsWrapper .otherButtonsInnerWrapper {
        width: 100%;
    }

    .c_section_title {
        width: 100%;
        font-size: 28px;
    }

    .otherButtonsMainWrapper {
        padding: 50px 0;
    }
}

@media (max-width:400px) {
    .c_section_title {
        font-size: 24px;
    }
}

/* End code for magic video */
/* code for servey form */


.serveyFormWrapper {
    margin: 0 auto;
    text-align: left;
}

.serveyFormWrapper form>div {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 30px auto;
    grid-gap: 25px;
    width: 100%;
}

.serveyFormWrapper {
    text-align: center;
}

.serveyFormWrapper .form-check label {
    background: #fff;
    padding: 15px 15px 15px 35px;
    border: 1px solid #404040;
    margin: 0;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all ease-in-out .3s;
    color: #404040;
}

.serveyFormWrapper .form-check label::before {
    content: '';
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border: 2px solid #0F6DEC;
    border-radius: 100%;
    transition: all 0.5s ease;
}

.otherButtonsInnerWrapper.disabled {
    opacity: 0.2;
    pointer-events: none;
}

.serveyFormWrapper .form-check input:checked+label::before {
    background-color: #0F6DEC;
    box-shadow: 0 0 0px 2px #0F6DEC;
}

.serveyFormWrapper .form-check {
    position: relative;
    margin: 0;
    padding: 0;
    width: calc(50% - 25px);
}

.serveyFormWrapper .other_custom_field.form-check {
    width: 100%;
    max-width: 40%;
}

.otherButton {
    position: relative;
    overflow: hidden;
}

.otherButton img {
    transition: all ease-in-out .3s;
}

.otherButton:hover img {
    transform: scale(1.06);
}

.serveyFormWrapper .other_custom_field.form-check label,
.serveyFormWrapper .other_custom_field.form-check input:hover label,
.serveyFormWrapper .other_custom_field.form-check input:checked label {
    border: 2px solid #3782f77d;
    background: #ecf3ff !important;
}

.serveyFormWrapper .form-check input {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
}

.serveyFormWrapper .form-check .form-check-input:checked+label {
    border-color: #3782f7;
    background: #ecf3ff;
}

.serveyFormWrapper .form-check input:hover+label {
    background: #f5f5f5;
}

.serveyFormWrapper form button.btn.btn-primary {
    border-style: none;
    font-size: 16px;
    width: 100%;
    color: #fff;
    background: #3782F7;
    border-radius: 5px;
    padding: 15px;
    transition: .5s ease-in-out;
    display: block;
    text-align: center;
    font-weight: 400;
    max-width: 522px;
    margin: 0 auto;
}

.serveyFormWrapper input:focus,
.serveyFormWrapper textarea:focus,
.serveyFormWrapper select:focus {
    outline: none;
    box-shadow: none;
}

.serveyFormWrapper .form-check+textarea {
    height: 70px;
    resize: none;
    border: 1px solid #404040;
    color: #404040;
    font-size: 15px;
    padding: 10px;
}

.Toastify {
    font-size: 14px;
}

.serveyFormWrapper form button.btn.btn-primary:hover {
    background: #0F6DEC;
}

@media (max-width:991px) {
    .serveyFormWrapper form>div {
        grid-gap: 18px 0;
        justify-content: space-between;
    }

    .serveyFormWrapper .form-check {
        width: 49%;
    }
}

@media (max-width:575px) {
    .serveyFormWrapper .form-check {
        width: 100%;
    }

    .serveyFormWrapper .other_custom_field.form-check {
        max-width: 100%;
    }

    .serveyFormWrapper form button.btn.btn-primary {
        max-width: 100%;
    }
}

/* End of code for servey form */


.primary-btn.btn-orange-ss button,
.primary-btn.btn-orange-ss a {
    background: #A357FF;
}

.youtubeWraaaper-container {
    display: flex;
}

.downlodingProgress {
    text-align: center;
    font-size: 14px;
}

p.ss-disclaimer-convert {
    font-size: 14px !important;
    max-width: 100% !important;
    margin: 10px !important;
}

p.ss-disclaimer-download {
    text-align: center !important;
    font-size: 14px !important;
    max-width: 90% !important;
    margin-bottom: 15px;
}

.vlogr_value_text {
    margin-bottom: 40px !important;
    border: 2px dashed #0F6DEC;
    border-radius: 6px;
    padding: 10px;
    background: #f1f7ff;
}

:root {
    --primary-color: #3782f7
}

::selection {
    color: #fff;
    background: var(--primary-color) !important
}

select option::selection {
    color: #fff;
    background: var(--primary-color) !important
}

.fade.paste_url-modal.modal.show {
    opacity: 1;
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 9999
}

.fade.modal-backdrop.show {
    z-index: 9999
}

.tool-error,
.vlog_tool_wrapper .tools-sec input.tool-error {
    background: #fff !important;
    color: #e91a3c !important;
    border: 2px solid #e70026e5 !important
}

.tools-sec p,
.tools-text {
    font-size: 16px
}

.bg_remover-wrapper>* {
    display: block;
    width: 100%
}

/* .video-compressor-sec.during-drap-video.during-drap-video-error {
    justify-content: space-between
} */

.tools-sec {
    margin: 60px auto 0;
    max-width: 522px;
    text-align: center
}

.sub-heading {
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 1.3;
    letter-spacing: .01em;
    color: #000
}

.video-compression-mode .tab-content>.active {
    opacity: 1
}

.main-heading {
    font-size: 52px;
    font-weight: 700;
    margin-bottom: 12px;
    line-height: 1.5;
    letter-spacing: .01em;
    color: #000
}

.sub-heading {
    font-size: 34px;
    max-width: 100%;
    font-weight: 700
}

.tools-heading p,
.tools-sec p {
    font-size: 16px;
    color: #000;
    line-height: 1.5;
    max-width: 464px;
    margin: 0 auto;
    font-weight: 400
}

.youtube-down-con,
.twitter-down-con,
.facebook-down-con,
.instagram-down-con,
.tiktok-down-con {
    margin-top: 50px
}

.vlog_tool_wrapper .tools-sec input {
    width: 100%;
    border-radius: 5px !important;
    border: 1px solid #f1f2f4 !important;
    background: #f1f2f4;
    padding: 8px 12px !important;
    font-size: 16px;
    font-weight: 300;
    color: #2f2f2f !important
}

.enter-url-popup-con .input-group input:focus {
    outline: none;
    background: transparent
}

.tools-sec input:focus,
.enter-url-popup-con .input-group input:focus {
    border: 1px solid var(--primary-color) !important
}

.enter-url-popup-con .input-group input.error-message {
    border: 2px solid #e70026e5 !important;
    background: none;
    color: #e70026e5
}

.video-compression-mode .row::before,
.video-compression-mode .nav:before {
    content: unset !important
}

.tools-sec input:focus-visible {
    outline: none
}

.tools-sec input::placeholder {
    color: #979797
}

.tools-sec input::-moz-placeholder {
    opacity: 1
}

.tools-sec input:-moz-placeholder {
    opacity: 1
}

.primary-btn button,
.primary-btn a {
    border: none;
    font-size: 18px;
    color: #fff;
    background: var(--primary-color);
    border-radius: 5px;
    width: 100%;
    padding: 15px 12px;
    transition: .5s ease-in-out;
    display: block;
    text-align: center;
    font-weight: 300
}

.primary-btn button:hover,
.primary-btn a:hover {
    color: #fff !important
}

.create-account-btn button:hover,
.create-account-btn button:focus,
button.disabled {
    background: #c3d9fd
}

button:hover {
    opacity: 1 !important
}

.primary-btn {
    margin-top: 120px
}

.youtube-down-con .primary-btn img,
.facebook-down-con .primary-btn img,
.twitter-down-con .primary-btn img,
.instagram-down-con .primary-btn img,
.tiktok-down-con .primary-btn img {
    max-width: 20px;
    margin-right: 10px;
    margin-bottom: 4px
}

.mp-btns {
    display: flex;
    align-items: center;
    margin: 20px -6px 0;
    max-width: 510px
}

.icons-content .primary-btn {
    max-width: 510px !important
}

.mp-btns.download-icon-size .mp-btn input[type="radio"]:checked+label {
    border: none;
    font-weight: 700
}

.mp-btns .mp-btn {
    width: 48%;
    position: relative;
    overflow: hidden
}

.mp-btns .mp-btn label {
    border-radius: 5px;
    border: 1px solid #404040;
    width: 100%;
    font-size: 18px;
    color: #404040;
    padding: 9px;
    cursor: pointer;
    font-weight: 400;
    cursor: pointer;
    text-align: center
}

.icons-content .mp-btns .mp-btn label {
    border: none;
    font-size: 14px !important
}

.mp-btns .mp-btn input {
    position: absolute;
    height: 80%;
    opacity: 0;
    cursor: pointer
}

.mp-btns .mp-btn input[type="radio"]:checked+label {
    background: #ecf3ff;
    border: 3px solid #1679ff;
    color: #1679ff;
    padding: 7px !important
}

.icons-content .mp-btns .mp-btn input[type="radio"]:checked+label,
.icons-content .mp-btns .mp-btn label {
    padding: 5px 15px !important
}

.video-size-sec.video-size-section {
    margin-top: 40px
}

.video-size-sec select:focus {
    outline: none
}

.loading-sec img {
    max-width: 30px
}

.loading-sec {
    margin: 60px 0 -40px
}

.video-size-sec select {
    background: #ecf3ff;
    border: 3px solid #1679ff;
    color: #1679ff;
    width: 100%;
    border-radius: 5px;
    padding: 8px;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    text-align: center;
    /* background-image: url(../images/video-dropdown.svg); */
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 97% 50%;
    -webkit-animation: none;
    animation: none;
}

.video-size-sec select::-ms-expand {
    display: none
}

.video-size-sec {
    margin-top: 10px;
    margin-bottom: -30px
}

.secondary-btn button {
    font-size: 18px;
    font-weight: 400;
    color: #797979;
    border: 1.5px solid #797979;
    border-radius: 5px;
    width: 100%;
    background: transparent;
    padding: 8px;
    transition: .5s ease-in-out
}

.secondary-btn button:hover {
    border-color: #797979 !important
}

.converting-video-con,
.converting-video-sec form p {
    margin-bottom: 60px
}

.secondary-btn button:focus-visible {
    outline: none
}

.converting-video-sec form {
    max-width: 330px;
    margin: 0 auto
}

.converting-video-con .progress-bar,
.youtube-down-con .progress,
.facebook-down-con .progress,
.twitter-down-con .progress,
.instagram-down-con .progress,
.tiktok-down-con .progress {
    height: 6px;
    width: 100%;
    background-color: #ecf3ff;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: unset;
    margin-bottom: 20px
}

.converting-video-con .bar {
    width: 0;
    height: 100%;
    background-color: var(--primary-color);
    animation: move 2s linear infinite;
    transition: width 2s ease-out;
    border-radius: 20px
}

.converting-video-con .perc {
    position: absolute;
    top: 10px;
    color: #24282f;
    font-weight: 400;
    font-size: 48px;
    left: 50%;
    transform: translateX(-50%)
}

.percentage-text {
    color: #24282f;
    font-weight: 600;
    font-size: 48px
}

.tools-heading .main-heading {
    color: #000
}

.converting-video-sec .sub-heading {
    font-weight: 700
}

.progress-bar-sec {
    position: relative;
    padding-top: 60px
}

.light-blue-btn a:visited {
    color: var(--primary-color)
}

.image-text-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 80px -20px 0
}

.image-text-wrapper .image-wrapper {
    width: 45%;
    padding: 0 20px 20px
}

.tools-completed-con {
    width: 45%;
    padding: 0 20px
}

.image-text-wrapper .image-wrapper img,
.completed-video-wrapper video {
    width: 100%
}

.completed-video-wrapper video {
    max-height: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 10px
}

.completed-video-wrapper {
    width: 55%;
    padding: 0 20px
}

.tools-completed-content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px
}

.image-text-wrapper .text-wrapper {
    width: 55%;
    margin-top: 20px;
    padding: 20px 15px
}

.image-text-wrapper .text-wrapper p {
    font-size: 16px;
    line-height: 1.5;
    margin: 40px 0 45px;
    max-width: 580px;
    color: #404040
}

.light-blue-btn button,
.light-blue-btn a {
    max-width: 510px;
    background: #ecf3ff;
    width: 100%;
    border: none;
    font-size: 18px;
    color: var(--primary-color) !important;
    padding: 16px 12px;
    transition: .5s ease-in-out
}

.light-blue-btn a {
    display: block;
    max-width: 100%;
    text-align: center
}

.tools-completed-con .light-blue-btn button {
    max-width: 100%;
    margin-top: 20px
}

.tools-back h4::before {

    background-repeat: no-repeat;
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    left: -30px;
    top: 50% !important;
    transform: translateY(-50%);
    background-size: cover
}

.tools-back h4.bckarr::before {

    left: -30px !important
}

.tools-back h4 {
    position: relative;
    font-size: 18px;
    color: #595959;
    font-weight: 400;
    margin-left: 36px;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 40px;
    transition: .1s ease-in-out
}

.tools-completed-con .primary-btn {
    margin-top: 60px
}

.video-comp-con .primary-btn {
    max-width: 300px;
    margin: 40px auto 20px
}

.video-comp-con form span {
    font-size: 16px;
    color: #979797;
    line-height: 1.5;
    display: block;
    margin-bottom: 20px;
    font-weight: 400
}

.video-comp-con .drag-drop-text {
    font-size: 16px;
    color: #404040;
    font-weight: 400;
    padding-bottom: 0;
    text-align: center;
}

.paste-url-sec {
    cursor: pointer;
    padding: 0 15px;
    margin: 0 auto;
    max-width: 100px
}

.paste-url-sec h4 {
    font-size: 14px;
    color: #797979;
    line-height: 1.5;
    font-weight: 400
}

.video-compressor-sec {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 40px !important;
    width: 100%;
    max-width: 100%;
    position: relative;
    flex-direction: column;
    border: 2px solid #ccc;
    border-style: dashed;
}

.video-comp-con .primary-btn button img {
    margin-right: 6px
}

.paste-url-sec img {
    margin-bottom: 10px
}

.during-drap-video {
    background: rgba(22, 121, 255, 0.05) !important;
    border: 4px dashed #1679ff;
    border-radius: 11px
}

.during-drap-video-error {
    background: rgba(237, 20, 56, 0.05) !important;
    border: 4px dashed rgba(237, 20, 56, 0.9);
    border-radius: 11px
}

.video-not-support {
    font-size: 16px;
    color: #404040;
    background: #fff;
    max-width: 388px;
    width: 90%;
    background: #fff;
    box-shadow: 0 4px 22px rgba(132, 0, 0, 0.14);
    border-radius: 53px;
    margin: 0 auto;
    padding: 26px 20px;
    cursor: pointer;
    font-weight: 400;
    position: absolute;
    top: 7px;
}

.enter-url-popup-con {
    width: 100%;
    text-align: center
}

.enter-url-popup-con h4 {
    font-size: 18px;
    color: #000;
    font-weight: 400;
    text-align: center
}

.enter-url-popup .tools-back h4,
.video-comp-back .tools-back h4,
.paste_url-modal .modal-dialog .modal-body .tools-back h4 {
    margin-top: 20px;
    font-size: 14px;
    color: silver;
    font-weight: 300
}

.video-comp-back .tools-back h4 {
    margin-left: 20px;
    font-size: 16px;
    color: #595959;
    margin-bottom: 15px
}

.video-comp-back .tools-back h4.bckarr {
    margin-left: 30px !important
}

.paste_url-modal .modal-dialog .modal-body .tools-back h4 {
    margin-left: 40px;
    font-size: 16px
}

.video-comp-back .tools-back h4::before,
.paste_url-modal .modal-dialog .modal-body .tools-back::before {

    left: -20px;
    top: 60%;
    filter: brightness(0.6)
}

.enter-url-popup .tools-back,
.paste_url-modal .modal-dialog .modal-body .tools-back {
    text-align: left;
    position: absolute;
    left: 0;
    top: 0
}

.paste_url-modal .modal-dialog .modal-body .tools-back h4::before {
    top: 50%;
    left: -22px;
    width: 18px;
    height: 18px;
    opacity: .5
}

.paste_url-modal .modal-dialog .modal-body .tools-back h4:hover::before {
    filter: brightness(1)
}

.enter-url-popup-con .input-group {
    margin: 30px 0 0 !important
}

.enter-url-popup-con .create-account-btn {
    margin-top: 30px
}

.enter-url-popup-con span {
    padding-top: 10px;
    display: block;
    font-size: 14px;
    font-weight: 500 !important
}

.create-account-btn button {
    font-size: 16px;
    font-weight: 400;
    background: var(--primary-color);
    border-radius: 6px;
    color: #fff;
    padding: 7px 15px;
    transition: .5s ease-in-out;
    min-width: 146px;
    border: none
}

.create-account-btn button:hover {
    background: var(--primary-color) !important
}

.enter-url-popup-con .uploading-video {
    font-size: 20px;
    color: #595959
}

.video-file-wrapper video {
    width: 100%;
    border-radius: 10px
}

.video-file-con {
    max-width: 400px;
    margin: 40px auto 0
}

.video-file-con h4 {
    font-size: 20px;
    color: #404040;
    font-weight: 600;
    margin-bottom: 20px;
    letter-spacing: 1px
}

.video-file-con ul {
    padding: 0;
    margin: 0
}

.video-file-con ul li {
    font-size: 16px;
    color: #404040;
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px
}

.video-file-con ul li span {
    text-align: right;
    max-width: 65%
}

.compression-mode-con {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px
}

.custom_flex {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column
}

.compression-mode-con .accordion-body .tools-text {
    color: #404040;
    margin-bottom: 20px;
    margin-top: 10px
}

.compression-mode-con .range {
    position: relative;
    width: 100%;
    height: 5px
}

.compression-mode-con .range input {
    width: 100%;
    position: absolute;
    top: 2px;
    height: 0;
    -webkit-appearance: none;
    appearance: none;
}

.compression-mode-con .range input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    margin: -3px 0 0;
    border-radius: 50%;
    cursor: pointer;
    border: 0 !important;
    background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 1px 8px rgba(0, 0, 0, 0.08)
}

.compression-mode-con .range input::-moz-range-thumb,
.compression-mode-con .range input::-ms-thumb {
    width: 20px;
    height: 20px;
    margin: -3px 0 0;
    border-radius: 50%;
    cursor: pointer;
    border: 0 !important;
    background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 1px 8px rgba(0, 0, 0, 0.08)
}

.compression-mode-con .range input::-webkit-slider-runnable-track,
.compression-mode-con .range input::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: #b2b2b2;
    border-radius: 3px
}

.compression-mode-con .range input::-ms-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: #b2b2b2;
    border-radius: 3px
}

.compression-mode-con .range input:focus {
    background: none;
    outline: none
}

.compression-mode-con .range input::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent
}

.compression-mode-con .range-labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 20px 0 30px
}

.compression-mode-autoPlaycon .range-labels li {
    position: relative;
    text-align: center;
    color: #979797;
    font-size: 12px;
    cursor: pointer
}

.compression-mode-con .range-labels .active {
    color: var(--primary-color)
}

.compression-mode-con .range-labels .selected::before {
    background: #37adbf
}

.compression-mode-con .range-labels .active.selected::before {
    display: none
}

.video-advanced-settings {
    margin-top: 20px
}

.video-advanced-settings .accordion-item {
    border: none
}

.video-advanced-settings button.accordion-button {
    background: transparent;
    box-shadow: none;
    font-size: 16px;
    color: #797979;
    font-weight: 400;
    max-width: 180px;
    margin-left: auto;
    padding-right: 0;
    display: flex;
    justify-content: flex-end
}

.video-advanced-settings .collapse {
    display: none;
    visibility: visible
}

.video-advanced-settings select.form-select {
    width: 80%;
    margin-left: auto;
    width: 80%;
    margin-left: auto;
    background: #fff;
    border: 1px solid #404040;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    padding: 8px
}

.comp-range-slider {
    width: 100%;
    max-width: 80%;
    margin-left: auto;
    margin-top: 30px
}

.video-advanced-settings .accordion-button::after,
.video-advanced-settings button.accordion-button.active.collapsed::after {
    transform: rotate(0) !important;
    margin-top: 10px !important;

    margin-left: 10px
}

.video-advanced-settings button.accordion-button.active::after {
    margin-top: 0 !important;
    transform: rotate(180deg) !important;
    transition: .3s ease-in-out
}

.video-advanced-settings .accordion-body {
    padding: 0
}

.estimated-comp-size ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end
}

.estimated-comp-size ul li.tools-text {
    font-size: 16px;
    color: #404040;
    max-width: 130px
}

.estimated-comp-size ul li.estimated-size {
    font-size: 30px;
    color: #404040;
    text-transform: uppercase;
    font-weight: 400
}

.estimated-comp-size .primary-btn {
    margin-top: 12px
}

.estimated-comp-size .primary-btn button {
    padding: 16px 10px;
    font-size: 18px
}

.compression-btn-sec {
    width: 80%;
    margin-left: auto
}

.compression-btn-sec .mp-btns label {
    font-size: 16px;
    text-align: center
}

.compression-frame-rate .range-slider {
    margin-top: 20px;
    width: 80%;
    margin-left: auto;
    position: relative
}

.compression-frame-rate {
    margin-top: 40px;
    position: relative;
    width: 100%;
    margin-left: auto
}

.range-slider__range {
    -webkit-appearance: none;
    appearance: none;
    width: calc(100% - (73px));
    height: 10px;
    border-radius: 5px;
    background: #d7dcdf;
    outline: none;
    padding: 0;
    margin: 0
}

.range-slider__range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    margin: 0;
    border-radius: 50%;
    cursor: pointer;
    border: 0 !important;
    background: #fff;
    box-shadow: 0 0 2px #000 0 1px 8px #000
}

.range-slider__range::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    margin: 0;
    border-radius: 50%;
    cursor: pointer;
    border: 0 !important;
    background: #fff;
    box-shadow: 0 0 2px #000 0 1px 8px #000
}

.range-slider__value {
    font-size: 15px;
    color: #404040;
    position: absolute;
    top: -30px;
    right: 0
}

.compression-frame-rate p.tools-text {
    margin-bottom: 40px
}

.compression-frame-rate .range-slider input {
    border: none;
    height: 4px;
    border-radius: 5px
}

.comp-range-slider span.range-slider__value {
    display: none
}

.comp-range-slider .compression-frame-rate {
    width: 100%
}

.comp-range-slider .range-slider {
    width: 100%
}

.video-advanced-settings select.form-select:focus {
    box-shadow: none
}

h5.reduction-size {
    font-size: 28px;
    color: #000;
    line-height: 1.5;
    margin: -8px 0 8px;
    font-weight: 400
}

.reduction-size-heading {
    font-size: 68px !important;
    font-weight: 400
}

.video-advanced-settings select.form-select {

    background-size: 14px;
    background-position: 94% 50%;
    background-repeat: no-repeat
}

.estimated-comp-size {
    margin: auto 0;
    width: 100%;
    margin-top: 20px;
}

.b-remover-icon {
    background: #7da6e4;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 50%;
    margin: 0 auto 50px;
    overflow: hidden
}

.icon-finder-logo {
    margin-bottom: 30px;
    margin-top: 60px
}

.icon-finder-sec .input-group {
    margin-bottom: 30px;
    margin-top: -10px
}

.tools-sec.icon-finder-sec {
    max-width: 100% !important;
    margin: 0 auto
}

.icon-finder-con {
    max-width: 630px;
    margin: 0 auto
}

.icon-finder-con .youtube-down-con,
.icon-finder-con .facebook-down-con,
.icon-finder-con .twitter-down-con,
.icon-finder-con .instagram-down-con,
.icon-finder-con .tiktok-down-con {
    max-width: 586px;
    margin: 50px auto 0
}

.icon-finder-sec .input-group input {
    padding-left: 40px !important;

    background-size: 20px;
    background-position: 2% 50%;
    background-repeat: no-repeat
}

.download-icon-sec {
    margin-top: 40px
}

.download-icon-sec .tools-completed-content {
    align-items: center;
    position: relative;
    padding-top: 60px
}

.download-icon-sec .completed-video-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column
}

.download-icon-sec .completed-video-wrapper input[type="color"] {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0
}

.download-icon-sec .tools-back {
    position: absolute;
    top: 0
}

.download-icon-sec .primary-btn button,
.primary-btn a {

    background-size: 20px;
    background-position: 97% 50%;
    background-repeat: no-repeat;
    padding: 16px 10px
}

.primary-btn button:active {
    border-color: #3782f7 !important
}

.primary-btn a:visited {
    color: #fff
}

.download-icon-sec .primary-btn button:focus-visible {
    outline: none
}

.download-icon-sec .primary-btn:nth-of-type(2) {
    margin-top: 20px
}

ul.download-icon-size {
    margin: 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0
}

ul.download-icon-size li.active,
ul.download-icon-size li:hover {
    background: #ecf3ff;
    font-weight: 600;
    color: var(--primary-color)
}

ul.download-icon-size li {
    font-size: 14px;
    color: #1c1c1c;
    border-radius: 4px;
    display: block;
    transition: .3s ease-in-out;
    padding: 6px 16px;
    cursor: pointer
}

.icon-finder-sec p {
    max-width: 100%
}

.tools-sec.during-drap-video {
    display: flex;
    flex-direction: column;
    padding: 20px 0 40px
}

.completed-video-wrapper {
    display: flex;
    justify-content: center;
    align-items: center
}

.image-wrapper video {
    width: 100%
}

.css-b62m3t-container {
    position: relative
}

.css-b62m3t-container::before {
    position: absolute;
    top: 12px;
    left: 9px;
    width: 15px;
    height: 15px;
    display: block;

    background-repeat: no-repeat;
    content: "";
    z-index: 9
}

.css-14el2xx-placeholder,
.css-qc6sy-singleValue {
    margin-left: 22px !important;
    font-size: 14px !important;
    font-weight: 600
}

audio.react-audio-player {
    background: #f1f3f4;
    border-radius: 4px !important
}

.vlog_tool_wrapper {
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column
}

.vlog_tool_wrapper .tools-completed-button .primary-btn,
.vlog_tool_wrapper .tools-completed-button .light-blue-btn,
.tools-completed-con .primary-btn {
    max-width: 510px;
    width: 100%
}

.icon-finder-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.icon-finder-logos>div {
    width: 14%;
    padding: 18px
}

.icon-finder-logos svg {
    width: 100%;
    height: auto;
    max-width: 48px
}

.icons-content svg {
    font-size: 202px;
    margin-bottom: 20px
}

.converting-video-sec .progress-bar-sec {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: auto;
    width: 40px;
    display: flex;
    justify-content: center;
    margin: -10px auto 30px
}


.tools-completed-button .light-blue-btn a {
    border-radius: 5px
}

.mp-btns.download-icon-size {
    margin-top: 6px !important
}

.download-icon-sec .tools-completed-content .tools-back h4::before {
    left: -20px !important
}

.download-icon-sec .tools-completed-content .tools-back h4 {
    padding-left: 4px;
    margin-left: 38px !important
}

.tools-completed-content .tools-back h4 {
    margin-left: 30px !important
}

.completed-video-wrapper .sketch-picker {
    max-width: 320px !important;
    width: 100% !important;
    height: auto
}

.completed-video-wrapper .sketch-picker>div {
    padding-bottom: 40% !important
}

.completed-video-wrapper .sketch-picker .flexbox-fix {
    padding-bottom: unset !important
}

input:focus {
    outline: none !important;
    border: 1px solid var(--primary-color) !important
}

.mp-btns.download-icon-size .mp-btn {
    padding: 6px 0
}

.vlog_tool_wrapper>label {
    width: 100%
}

.sc-gsnTZi.eWRUdH,
.sc-gsnTZi.emNpaz {
    background: transparent !important;
    border: none !important;
    opacity: 0
}

.tools-completed-sec .completed-video-wrapper img {
    max-height: 400px;
    object-fit: contain
}

.primary-btn a:hover,
.primary-btn a:focus,
.primary-btn a:active {
    color: #fff !important
}

.tools-completed-con p.tools-text {
    color: #000;
    font-weight: 400
}

.tools-completed-content .primary-btn a {
    background-image: unset !important
}

.icon-finder-logos i {
    font-size: 40px;
    padding-right: 10px
}


.icons-content h1.main-heading {
    line-height: 1.2
}

.icons-content .primary-btn {
    margin-top: 40px
}

.background-remover-content .primary-btn {
    max-width: 423px !important
}

.during-drap-video-error .tools-heading {
    margin-top: 50px
}

.no-found {
    text-align: center;
    font-size: 20px
}

.tools-completed-button .sub-heading {
    font-size: 44px;
    max-width: none;
    line-height: 1.3
}

.tools-completed-content.background-remover-content {
    margin-bottom: 20px
}

.tools-completed-con .sub-heading {
    font-size: 44px;
    max-width: none;
    line-height: 1.3;
    margin-bottom: 20px
}

.tools-completed-button .image-wrapper {
    padding-top: 0
}

.tools-completed-button .image-text-wrapper .text-wrapper {
    margin-top: 28px
}

.tools-completed-button .image-text-wrapper {
    margin-bottom: 0
}

.background-remover-content .completed-video-wrapper {

    padding: 0
}

.down-content-wrapper .image-text-wrapper .text-wrapper {
    margin-top: 50px
}

.mar_top {
    margin-top: 10rem !important;
}


.tools-completed-content.background-remover-content .completed-video-wrapper {
    min-height: 400px
}

section.facebook-down-sec.tools-sec,
section.twitter-down-sec.tools-sec,
section.tiktok-down-sec.tools-sec,
section.instagram-down-sec.tools-sec,
section.youtube-down-sec {
    max-width: 750px;
    width: 100%;
}

section.facebook-down-sec.tools-sec.converting-video-sec,
section.twitter-down-sec.tools-sec.converting-video-sec,
section.tiktok-down-sec.tools-sec.converting-video-sec,
section.instagram-down-sec.tools-sec.converting-video-sec {
    margin-bottom: 20px
}

section.facebook-down-sec.tools-sec .input-group,
section.twitter-down-sec.tools-sec .input-group,
section.tiktok-down-sec.tools-sec .input-group,
section.instagram-down-sec.tools-sec .input-group {
    margin-bottom: 10px !important
}

.facebook-down-sec.tools-sec h1.main-heading,
.twitter-down-sec.tools-sec h1.main-heading,
.tiktok-down-sec.tools-sec h1.main-heading,
.instagram-down-sec.tools-sec h1.main-heading {
    font-size: 52px
}

.facebook-down-con,
.twitter-down-con,
.tiktok-down-con,
.instagram-down-con,
.youtube-down-con {
    max-width: 522px;
    margin: 0 auto;
    margin-top: 60px
}

.facebook-down-con .percentage-text,
.twitter-down-con .percentage-text,
.tiktok-down-con .percentage-text,
.instagram-down-con .percentage-text {
    margin-bottom: 10px
}

section.tiktok-down-sec.tools-sec .mp-btns,
section.instagram-down-sec.tools-sec .mp-btns {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin-top: 6px;
    margin-bottom: 140px !important
}

.tiktok-down-con .primary-btn,
.instagram-down-con .primary-btn {
    margin-bottom: 20px
}

section.facebook-down-sec.tools-sec .mp-btns,
section.twitter-down-sec.tools-sec .mp-btns {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin-top: 6px;
    margin-bottom: 0 !important
}

.facebook-down-con .primary-btn,
.twitter-down-con .primary-btn,
.tiktok-down-con .primary-btn,
.instagram-down-con .primary-btn {
    margin-top: 120px !important;
    margin-bottom: 20px
}

.supports-popular-sec.tools-supports-popular .supports-popular-con {
    background: #f1f2f4 !important
}

.supports-popular-sec.tools-supports-popular h4 {
    font-weight: 500 !important;
    font-size: 24px !important;
    max-width: 428px;
    margin: 0 auto 30px;
    line-height: 1.3
}

.supports-popular-sec.tools-supports-popular {
    margin-bottom: 60px
}

.page_type_vlogr-vlogr-tools .tools-completed-button .image-text-wrapper {
    margin-bottom: -60px
}

.facebook-down-con .loading-sec,
.twitter-down-con .loading-sec,
.tiktok-down-con .loading-sec,
.instagram-down-con .loading-sec,
.youtube-down-con .loading-sec {
    /* margin: 0 !important; */
    position: absolute;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0
}

.facebook-down-con .loading-sec img,
.twitter-down-con .loading-sec img,
.tiktok-down-con .loading-sec img,
.instagram-down-con .loading-sec img {
    position: absolute;
    top: 35px
}

.youtube-down-con .video-size-sec.video-size-section {
    margin-top: 45px;
    margin-bottom: -90px
}

.youtube-down-con .loading-sec img {
    position: absolute;
    top: 68px
}

.youtube-down-con .primary-btn {
    margin-top: 140px
}

.facebook-down-con .video-size-sec.video-size-section,
.twitter-down-con .video-size-sec.video-size-section {
    margin-bottom: -50px;
    margin-top: 20px
}

.supports-popular-sec.tools-supports-popular .supports-popular-con ul {
    padding-left: 0 !important
}

.youtube-down-sec .youtube-down-con .mp-btns .mp-btn {
    padding: 0 6px !important;
    margin-top: 0;
    width: 49%;
    padding: 0 3px !important
}

.youtube-down-sec .youtube-down-con .mp-btns {
    justify-content: space-between;
    max-width: unset !important;
    margin: 20px -3px 0
}

.compression-mode-con {
    padding: 18px;
    border: 1px solid #e0e0e0;
    border-radius: 10px
}

.tabs_wrapper .nav-tabs {
    border: 0
}

.tabs_wrapper .nav-tabs .nav-item {
    flex: 1 1 50%
}

.tabs_wrapper .nav-tabs .nav-item .nav-link {
    font-size: 16px;
    line-height: 1.6;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    color: #404040;
    padding: 10px;
    font-weight: 300;
    height: 100%
}

.tabs_wrapper .nav-tabs .nav-item .nav-link.active {
    background: #ecf3ff;
    border-color: #ecf3ff;
    color: #595959
}

.tabs_wrapper .tab-content .form-select {
    padding: 10px;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    border: 1px solid #404040;
    border-radius: 5px;
    max-width: 362px;
    margin: 0 auto;
    width: 100%;
    outline: none;
    box-shadow: none
}

.compression-mode-con .tabs_wrapper .tab-content .form-select {
    margin: 0 0 0 auto
}

.tabs_wrapper .tab-content .range-labels li {
    font-size: 14px;
    font-weight: 400;
    color: #979797
}

.tabs_wrapper .tab-content .range-slider__tooltip {
    display: none
}

.compression_tool-label {
    font-size: 16px;
    font-weight: 700;
    color: #404040;
    margin-bottom: 40px
}


/* Only for drag and drop on shortscreator */

/* Common need on both */
.ss_custom_dragDrop .video-not-support {
    color: #ee2a4b;
    font-weight: bold;
}

video#mp4uploadVideo {
    max-width: 100%;
}

option:disabled {
    background-color: #80808070;
    cursor: not-allowed;
}

.afterUploadPreview .video-comp-con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16px;
}

.afterUploadPreview .video-comp-con p {
    padding: 0;
    margin: 0;
    color: #C3C3C3;
}

section.video-compressor-sec.tools-sec.afterUploadPreview {
    padding: 0;
    height: auto;
}

.tiktokFormAndVideoWraper {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
}

.tiktokFormAndVideoWraper .userinfoWrapperWrap {
    width: 35%;
}

.tiktokFormAndVideoWraper form.ss_tiktokForm {
    width: 60%;
}

.btn-full {
    width: 100%;
    color: #fff
}

/* Non Connectd Accounts CSS Start Here */
.completeFormWrapper .noConnectedAccountBody {
    text-align: center;
    width: 100%;
}

/* .completeFormWrapper .noConnectedAccountBody {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    text-align: center;
    width: 100%;
} */

.completeFormWrapper .noConnectedAccountBody p {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    color: rgba(17, 23, 29, .6) !important;
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
    margin-bottom: 8px;
}

.completeFormWrapper .noConnectedAccountBody .btn {
    display: block;
    margin: 20px auto 0;
    border-radius: 10px;
    background-color: #3782f7;
    color: #fff;
    padding: 15px 30px;
    font-family: 'Poppins', sans-serif !important;
    line-height: 1.2;
    font-size: 16px;
    font-weight: 600;
    transition: all .5s ease-in-out;
    border-style: none;
}

.completeFormWrapper .noConnectedAccountBody .btn:hover {
    background-color: #0f6dec;
}

/* Non Connectd Accounts CSS Close Here */
/* Scoial Accounts Popup CSS Start Here */
.fade.ss_modal.addPlateformModel .modal-dialog .modal-content .modal-header {
    position: sticky;
    left: 0;
    top: 0;
    padding: 20px 15px;
    background-color: #fff;
    z-index: 999;
    margin-bottom: 20px;
    border-bottom: 1px solid #bbb;
}

.fade.ss_modal.addPlateformModel .modal-dialog .modal-content {
    padding: 0 30px 30px;
    max-width: 650px;
}

.fade.ss_modal.addPlateformModel .modal-dialog .modal-content .modal-header h3 {
    margin: 0;
    font-size: 26px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700;
}

.fade.ss_modal.addPlateformModel .modal-dialog .modal-content .modal-header .btn-close {
    position: absolute;
    right: -10px;
    top: 20px;
}

.fade.ss_modal.addPlateformModel .modal-body .plateformListItems {
    display: flex;
    grid-gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
}

.fade.ss_modal.addPlateformModel .modal-body .plateformListItems .plateformListItem .linkWrapper {
    border: 1px solid #bbb;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    display: block;
}

.fade.ss_modal.addPlateformModel .modal-body .plateformListItems .plateformListItem .linkWrapper:hover {
    border-color: #3782f7;
}

.fade.ss_modal.addPlateformModel .modal-body .plateformListItems .plateformListItem .linkWrapper img {
    max-width: 24px;
}

.fade.ss_modal.addPlateformModel .modal-body .plateformListItems .plateformListItem .linkWrapper p {
    font-size: 16px;
    font-family: 'Poppins', sans-serif !important;
    margin-top: 12px;
    font-weight: 500;
}

@media (max-width:767px) {
    .fade.ss_modal.addPlateformModel .modal-dialog .modal-content {
        padding: 0 15px 20px;
    }

    .fade.ss_modal.addPlateformModel .modal-dialog .modal-content .modal-header .btn-close {
        right: 5px;
        top: 20px;
    }

    .fade.ss_modal.addPlateformModel .modal-dialog .modal-content .modal-header h3 {
        font-size: 24px;
    }
}

@media (max-width:450px) {
    .fade.ss_modal.addPlateformModel .modal-dialog .modal-content .modal-header h3 {
        font-size: 20px;
    }

    .fade.ss_modal.addPlateformModel .modal-body .plateformListItems {
        grid-template-columns: 1fr;
    }

    .fade.ss_modal.addPlateformModel .modal-dialog .modal-content .modal-header {
        padding: 20px 0 15px;
        margin-bottom: 25px;
    }

    .fade.ss_modal.addPlateformModel .modal-body {
        padding: 0;
    }
}

/* Scoial Accounts Popup CSS Close Here */
/** New UI for share popup functionality */

body.dcs-iframe-only {
    max-width: 1024px;
    margin: 0 auto;
    /* margin-top: 30px; */
}

.connectedPlateforms-container {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 25px;
}

.connectedPlateforms-container .connectedPlateform {
    border: 1px solid #ddd;
    padding: 30px 20px;
    border-radius: 10px;
    min-height: 450px;
}

.connectedPlateforms-container .connectedPlateform.connectedPlateforms-container-left {
    width: 300px;
}

.connectedPlateforms-container-left {
    position: relative;
}

.connectedPlateforms-container-left  button
 {
    position: absolute;
    bottom: 30px;
    left: 20px;
    font-family: 'Poppins', sans-serif !important;
    line-height: 1.2;
    padding: 12px 30px;
    transition: all .7s ease-in-out;
}

.allPlateformText {
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
    text-align: left;
    font-family: 'Poppins', sans-serif !important;
    line-height: 1.2;
    font-size: 16px;
}

.allplateformsWrapper .plateformItem:not(:last-child) {
    margin-bottom: 10px;
}

.allplateformsWrapper .plateformItem {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    padding: 10px 10px;
    grid-gap: 10px;
    transition: all .7s ease-in-out;
    border-radius: 5px;
    cursor: pointer;
}

.allplateformsWrapper .plateformItem.active,
.allplateformsWrapper .plateformItem:hover {
    border-color: #3782f7;
}

.allplateformsWrapper .plateformItem.active {
    position: relative;
}

.allplateformsWrapper .plateformItem.active::after {
    content: '\2713';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    font-size: 15px;
    font-weight: 700;
    color: #3782f7;
}

.allplateformsWrapper .plateformItem img {
    max-width: 20px;
}

.allplateformsWrapper .plateformItem p {
    margin: 0;
    padding: 0;
    text-align: left;
    font-family: 'Poppins', sans-serif !important;
    line-height: 1.2;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: rgba(17, 23, 29, .6) !important;
    position: relative;
}

.connectedPlateforms-container-right {
    flex: 1;
    position: relative;
}

.connectedPlateforms-container-right .selectSchText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    font-family: 'Poppins', sans-serif !important;
    line-height: 1.2;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: rgba(17, 23, 29, .6) !important;
}

.connectedPlateforms-container-right .selectSchText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    font-family: 'Poppins', sans-serif !important;
    line-height: 1.2;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: rgba(17, 23, 29, .6) !important;
}

.connectedPlateforms-container-right .formWrapper {
    margin: 0;
}

.connectedPlateforms-container-right .formWrapper h3 {
    font-family: 'Poppins', sans-serif !important;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
    line-height: 1.3;
}

.connectedPlateforms-container-right .formWrapper .inputWrapper {
    padding: 0;
    border-style: none;
    margin-bottom: 18px;
}

.connectedPlateforms-container-right .formWrapper .inputWrapper label {
    font-family: 'Poppins' !important;
    font-size: 14px;
    color: rgba(17, 23, 29, .6);
    margin-bottom: 8px;
}

.connectedPlateforms-container-right .formWrapper .inputWrapper input,
.connectedPlateforms-container-right .formWrapper .inputWrapper textarea,
.connectedPlateforms-container-right .formWrapper .select-wrapper .accessSpecifiers {
    font-size: 15px;
    padding: 15px !important;
    display: block;
    font-family: 'Poppins' !important;
    font-weight: 500;
    border: 1px solid #ddd;
    line-height: 1.2;
    background-color: #fff;
}
@media (max-width:767px){
    .connectedPlateforms-container .connectedPlateforms-container-right{
        flex:none;
        width: 100%;
        min-height: 200px;
    }
    .connectedPlateforms-container .connectedPlateform.connectedPlateforms-container-left {
        width: 100%;
        min-height: auto;
    }
    .connectedPlateforms-container-left  button {
        position: unset;
        margin-top: 30px;
    }
    .connectedPlateforms-container-right .formWrapper h3 {
        font-size: 20px;
        margin-bottom: 20px;
    }
}

.connectedPlateform.connectedPlateforms-container-right form.ss_tiktokForm {
    width: 100%;
}

.datePickerFooterWrap button.btn{
    color:#fff;
}
.datePickerFooterWrap button.btn.ss-danger {
    background: #dc3545;
}
.datePickerFooterWrap button.btn.ss-primary {
    background: #3782f7;
}

.datePickerFooterWrap {
    gap: 10px;
    display: flex;
    justify-content:center;
}

.fade.ss_modal.addPlateformModel .modal-body .plateformListItems > div {
    flex: 48%;
    max-width: 49%;
}

button.InstaConnect {
    background: #1877F2 !important;
}
button.InstaConnect:hover {
    background: #1877F2 !important;
}
button.InstaConnect {
    align-items: center;
}

._loading_overlay_wrapper._loading_overlay_wrapper--active {
    display: contents;
}
._loading_overlay_wrapper._loading_overlay_wrapper--active>div{
    position: fixed;
}


label.form-label {
    font-size: 16px;
    font-weight: 700;
}