.word {
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s;
}

.word:hover {
  background-color: rgba(128, 128, 128, 0.5);
}

.toolbar {
  position: absolute;
  background-color: black; /* 검정색 배경 */
  border-radius: 8px; /* 모서리 둥글게 */
  padding: 5px; /* 패딩 추가 */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 추가 */
  display: flex; /* 버튼들을 가로로 배열 */
  gap: 4px; /* 버튼 사이 간격 */
  transform: translateX(-50%) translateY(-100%);
}

.toolbar button {
  border: none; /* 테두리 제거 */
  background-color: transparent; /* 배경색 투명 */
  color: white; /* 텍스트 흰색 */
  cursor: pointer; /* 마우스 오버 시 커서 변경 */
  padding: 5px 10px; /* 패딩 설정 */
}

.color-button {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: green;
  border: 2px solid white; /* 흰색 테두리 추가 */
}
