.timezone-selector {
    margin-top: 10px;
}

/* Datetime picker css */
/* .rdt .OpenDatePicker button {
    border-radius: 5px;
    background-color: #3782f7;
    border-style: none;
    color: #fff;
    text-align: center;
    display: block;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.36px;
    margin:0 auto 20px;
    transition: all 0.7s ease;
    padding: 15px 25px;
    line-height: 1;
    font-family: "Poppins", sans-serif !important;
    width: fit-content;
} */
/* .rdtPicker {
    max-width: 400px;
    margin: 0 auto;
    border-style: none;
    position: relative;
    padding: 25px 20px;
    border-radius: 6px;
} */
.rdt > div + .rdtPicker {
    border-style: none;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    background-color: rgb(0, 0, 0, 0.3);
    z-index: 99999 !important;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rdtPicker .calenderWrapperNew {
    max-width: 450px;
    background-color: #fff;
    width: 100%;
    padding: 25px;
    position: relative;
    border-radius: 15px;
}
.rdt > .rdtPicker:empty {
    display: none;
}
.rdtPicker .close-icon {
    position: absolute;
    top: 3px;
    right: 6px;
    font-size: 24px;
    line-height: 1;
    padding: 0;
    background-color: transparent !important;
    width: fit-content !important;
}
.rdtPicker .rdt .form-control {
    display: none;
}

.rdtPicker .rdt .rdtPicker {
    box-shadow: none;
    border-style: none;
    padding: 0;
    max-width: 100%;
    width: 100%;
    outline: none;
    position: unset;
}

.rdtPicker .rdt  table:first-child thead tr:first-child {
    background-color: #3782f7;
}

.rdtPicker .rdt  table:first-child thead tr:first-child th.rdtSwitch {
    color: #fff;
    font-family: "Poppins", sans-serif !important;
    font-size: 15px;
    font-weight: 600;
    width: 60% !important;
    line-height: 1.3;
}

.rdtPicker .rdt  table:first-child thead tr:first-child th.rdtPrev {
    color: #fff;
    width: 20% !important;
    font-size: 24px;
    line-height: 1;
}

.rdtPicker .rdt  table:first-child thead tr:first-child  th.rdtNext {
    color: #fff;
    width: 20% !important;
    font-size: 24px;
    line-height: 1;
}

.rdtPicker .rdt  table:first-child thead tr:first-child th {
    padding: 10px 15px;
    transition: all .5s ease-in-out;
    border-color: #3782f7;
}

.rdtPicker .rdt  table:first-child thead tr:first-child th:hover {
    background-color: #0f6dec;
}
.rdtPicker .rdt table:first-child thead tr th {
    font-size: 14px;
    color: #000;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600;
    padding: 5px 0;
    border:1px solid rgba(57, 76, 96, .15);
}
.rdtPicker .rdt .rdtPicker table tbody{
    border: 1px solid rgba(57, 76, 96, .15);
}
.rdtPicker .rdt .rdtPicker table tbody tr td {
    padding: 8px 5px;
    line-height: 1;
    color: rgba(17, 23, 29, .6);
    font-family: "Poppins", sans-serif !important;
    width: 14.2% !important;
}
.rdtPicker .rdt .rdtPicker table tbody tr td:hover {
    background-color: #eee;
    color: #444;
}
.rdtPicker .rdt .rdtPicker table tbody tr td.rdtActive {
    background-color: #0f6dec;
    color: #fff;
}
.rdtPicker .rdt .rdtPicker table tbody tr td.rdtToday:before {
    border-bottom-color: #0f6dec;
}
.rdtPicker .rdt .rdtPicker table tbody tr td.rdtActive.rdtToday:before {
    border-bottom-color: #ffffff;
}
.rdtPicker .time-picker{
    display: flex;
    align-items: center;
    grid-gap: 10px;
    flex-wrap: wrap;
    margin: 15px 0;
    justify-content: center;
}
.rdtPicker .time-picker select {
    border: 1px solid rgba(57, 76, 96, .15);
    flex: 1;
    padding: 10px 15px;
    line-height: 1;
    border-radius: 30px;
    outline: none;
    color: #444;
    font-size: 16px;
    appearance: none;
    text-align: center;
    font-family: "Poppins", sans-serif !important;
    font-weight: 500;
}   
.rdtPicker .timezone-picker > .btn-group{
    border: 1px solid rgba(57, 76, 96, .15);
    padding: 10px 15px;
    line-height: 1;
    border-radius: 30px;
    outline: none;
    color: #444;
    font-size: 16px;
    text-align: center;
    font-family: "Poppins", sans-serif !important;
    font-weight: 500;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    justify-content: center;
    position: relative;
}
.rdtPicker .timezone-picker > .btn-group .btn {
    padding: 0;
    width: fit-content;
    max-width: fit-content;
    background-color: transparent;
}
.rdtPicker .timezone-picker > .btn-group .btn-group{
    position: static;
    width: fit-content;
}
.rdtPicker .timezone-picker > .btn-group .btn-group .custom-dropdown-menu{
    left: 0 !important;
    transform: translate(0) !important;
    top: 100% !important;
    width: 100% !important;
    height: 400px;
    overflow: auto;
    padding: 10px;
}
.rdtPicker .timezone-picker > .btn-group .btn-group input {
    border: 1px solid rgba(57, 76, 96, .15);
    padding: 10px 15px;
    line-height: 1;
    border-radius: 30px;
    outline: none;
    color: #444;
    font-size: 16px;
    text-align: center;
    font-family: "Poppins", sans-serif !important;
    font-weight: 500;
    margin: 0  0 15px!important;
    width: 100% !important;
}
.rdtPicker .timezone-picker > .btn-group .btn-group .custom-dropdown-menu a{
    background-color: #eee;
    padding: 10px;
    margin-bottom: 10px;
    display: block;
}
.rdtPicker .timezone-picker > .btn-group .btn-group .custom-dropdown-menu a.active, 
.rdtPicker .timezone-picker > .btn-group .btn-group .custom-dropdown-menu a:hover{
    background-color: #0f6dec;
}
.rdtPicker .timezone-picker > .btn-group .btn-group .custom-dropdown-menu a span{
    color: #444;
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;
    text-wrap: wrap;
    line-height: 1.2;
}
.rdtPicker .timezone-picker > .btn-group .btn-group .custom-dropdown-menu a.active span,
.rdtPicker .timezone-picker > .btn-group .btn-group .custom-dropdown-menu a:hover span{
    color: #fff;
}
.rdtPicker .rdt .rdtPicker table {
    width: 100% !important;
}
@media (max-width:767px){
    .rdtPicker .rdt .rdtPicker table tbody tr td,
    .rdtPicker .rdt table:first-child thead tr th {
        width: 14.2% !important;
    }
}
@media (max-width:575px){
    .rdtPicker {
        padding: 10px 10px 20px;
    }
    .rdtPicker .close-icon {
        top: -10px;
        right: -6px;
        background-color: #3782f7 !important;
        color: #fff;
        width: 20px !important;
        height: 20px;
        z-index: 9;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 21px;
    }
}
/* Datetime picker css */